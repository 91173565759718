<template>
  <div class="mobile">
    <div class="header">
      <img class="logo" src="../assets/mobile/通通logo.png" alt="">
      <div class="icon">
        <van-icon name="bars" />
      </div>
    </div>
    <div class="picTop">
      <div class="picTopContent">
        <p class="picTopA">T-iPower</p>
        <p class="picTopB">通通换电简介</p>
        <div class="picTopC"></div>
        <p class="picTopD">通通换电作为能源新势力，沈阳最大、覆盖最广、适配车型最多、技术领先的充换电运营服务商，我们不仅提供智能换电综合补能服务，更提供能源、电池、大数据等全方位的新能源解决方案服务。</p>
      </div>
    </div>
    <div class="picCenter">
      <div class="picTopContent">
        <p class="picTopA">About Us</p>
        <p class="picTopB">关于我们</p>
        <div class="picTopC"></div>
        <p class="picTopD">通通换电作为能源新势力，沈阳最大、覆盖最广、适配车型最多、技术领先的充换电运营服务商，我们不仅提供智能换电综合补能服务，更提供能源、电池、大数据等全方位的新能源解决方案服务。</p>
      </div>
    </div>
    <div class="picBottom">
      <div class="picTopContent">
        <p class="picTopA">About Us</p>
        <p class="picTopB">关于我们</p>
        <div class="picTopCV"></div>
        <div class="pictureRight">
          <p class="titleTwo4">辽宁软通智新科技有限公司</p>
          <div class="titleTwo5"></div>
          <p class="titleTwo6">公司地址</p>
          <p class="titleTwo7">辽宁省沈阳市沈河区青年大街268-1号2101</p>
          <p class="titleTwo8">电话</p>
          <p  class="titleTwo9">400-1212-6688</p>
          <p  class="titleTwo10">邮箱</p>
          <p class="titleTwo11">shenguyoulan@tongtong.com</p>
        </div>
      </div>
      <div class="fotter">
        <p>Copyright© 2023 辽宁软通智新科技有限公司</p>
        <a class="filingNumber"  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2023010593号-2</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },

}
</script>
<style lang="less" scoped>
.mobile {
  .header {
    width: 100%;
    height: 1.76rem;
    background: #000000;
    display: flex;
    justify-content: space-between;
    box-sizing:border-box;
    padding: 1.06rem 0.3rem 0 0.3rem;
    z-index: 999;
    position: fixed;
    top: 0;
    .logo {
      width: 1.6rem;
      height: 0.52rem;
    }
    .icon {
      color: #fff;
      width: 0.44rem;
      height: 0.44rem;
      font-size: 0.5rem;
    }
  }
  .picTop {
    // width: 100%;
    height: 11.6rem;
    background-image: url(../assets/mobile/1_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow:hidden;
    display: flex;
    justify-content: center;
    .picTopContent {
      width: 5rem;
      height: 3.5rem;
      z-index: 900;
      margin-top: 6.4rem;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      .picTopA {
        width: 1.95rem;
        height: 0.5rem;
        font-family: STHupo;
        font-size: 0.5rem;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 0.5rem;
        font-weight: 400;
      }
      .picTopB {
        width: 1.44rem;
        height: 0.24rem;
        font-family: PingFangSC-Medium;
        font-size: 0.24rem;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 0.24rem;
        font-weight: 500;
        margin-top: 0.1rem;
      }
      .picTopC {
        width: 0.6rem;
        height: 0.05rem;
        background: #fff;
        margin-top: 0.3rem;
      }
      .picTopD {
        width: 85%;
        height: 1.4rem;
        font-family: PingFangSC-Medium;
        font-size: 0.22rem;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 0.34rem;
        font-weight: 500;
        margin-top: 0.5rem;
      }
    }
  }
  .picCenter {
    height: 9rem;
    background-image: url(../assets/mobile/2_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow:hidden;
    display: flex;
    justify-content: center;
    .picTopContent {
      width: 6rem;
      height: 3.4rem;
      z-index: 900;
      display: flex;
      margin-top: 3.9rem;
      flex-direction: column;
      align-items: center;
      .picTopA {
        width: 1.95rem;
        height: 0.5rem;
        font-family: STHupo;
        font-size: 0.5rem;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 0.5rem;
        font-weight: 400;
      }
      .picTopB {
        width: 1.44rem;
        height: 0.24rem;
        font-family: PingFangSC-Medium;
        font-size: 0.24rem;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 0.24rem;
        font-weight: 500;
        margin-top: 0.1rem;
      }
      .picTopC {
        width: 0.6rem;
        height: 0.05rem;
        background: #FFFFFF;
        margin-top: 0.3rem;
        border: 1px solid #fff;
      }
      .picTopD {
        width: 75%;
        height: 1.4rem;
        font-family: PingFangSC-Medium;
        font-size: 0.22rem;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 0.34rem;
        font-weight: 500;
        margin-top: 0.5rem;
      }
    }
  }
  .picBottom {
    // width: 100%;
    height: 8.8rem;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url(../assets/mobile/3_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .fotter {
      width: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      // justify-content: space-between;
      p {
        width: 5rem;
        height: 0.33rem;
        font-family: PingFangSC-Regular;
        font-size: 0.24rem;
        color: #666666;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom: 0.1rem;
      }
      .filingNumber {
        text-decoration: none;
        color: #666666;
        font-family: PingFangSC-Regular;
        // margin-top: 0.1rem;
        font-size: 0.28rem;
      }
    }
    .picTopContent {
      width: 6rem;
      height: 3.4rem;
      z-index: 900;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      margin-top: 1rem;
      .picTopA {
        width: 1.95rem;
        height: 0.5rem;
        font-family: STHupo;
        font-size: 0.5rem;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 0.5rem;
        font-weight: 400;
      }
      .picTopB {
        width: 1.44rem;
        height: 0.24rem;
        font-family: PingFangSC-Medium;
        font-size: 0.24rem;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 0.24rem;
        font-weight: 500;
        margin-top: 0.1rem;
      }
      .picTopCV {
        width: 0.6rem;
        height: 0.05rem;
        background: #000000;
        border: 1px solid #000000;
        margin-top: 0.3rem;
      }
      .pictureRight {
        width: 85%;
        height: 5rem;
        background: rgba(21,29,38,0.70);
        border-radius: 0.2rem;
        margin-top: 0.3rem;
        .titleTwo4 {
          width: 3.12rem;
          height: 0.26rem;
          font-family: PingFangSC-Semibold;
          font-size: 0.26rem;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 0.26rem;
          font-weight: 600;
          margin: 0.83rem 0 0.2rem 0.5rem;
        }
        .titleTwo5 {
          width: 3.12rem;
          height: 0.02rem;
          background: #FFFFFF;
          margin: 0 0 0.4rem 0.5rem;
        }
        .titleTwo6 {
          // width: 0.56rem;
          height: 0.14rem;
          font-family: PingFangSC-Regular;
          font-size: 0.14rem;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 0.14rem;
          font-weight: 400;
          margin: 0 0 0.1rem 0.5rem;
        }
        .titleTwo7 {
          width: 3.9rem;
          height: 0.2rem;
          font-family: PingFangSC-Semibold;
          font-size: 0.2rem;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 0.2rem;
          font-weight: 600;
          margin: 0 0 0.3rem 0.5rem;
        }
        .titleTwo8 {
          width: 2.8rem;
          height: 0.14rem;
          font-family: PingFangSC-Regular;
          font-size: 0.14rem;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 0.14rem;
          font-weight: 400;
          margin: 0 0 0.1rem 0.5rem;
        }
        .titleTwo9 {
          width: 1.8rem;
          height: 0.2rem;
          font-family: PingFangSC-Semibold;
          font-size: 0.2rem;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 0.2rem;
          font-weight: 600;
          margin: 0 0 0.4rem 0.5rem;
        }
        .titleTwo10 {
          width: 2.8rem;
          height: 0.14rem;
          font-family: PingFangSC-Regular;
          font-size: 0.14rem;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 0.14rem;
          font-weight: 400;
          margin: 0 0 0.1rem 0.5rem;
        }
        .titleTwo11 {
          width: 2.9rem;
          height: 0.2rem;
          font-family: PingFangSC-Semibold;
          font-size: 0.2rem;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 0.2rem;
          font-weight: 600;
          margin: 0 0 0.63rem 0.5rem;
        }
      }
      
    }
  }
}
</style>