<template>
	<div class="homePage">
		<div class="picture">
			<img src="../../assets/images/banner2.png" alt="">
			<div class="picturer">
				<p class="p1">中国数字技术和服务创新领导企业</p>
				<p class="p2">致力于用数字技术提升客户价值</p>
			</div>
		</div>
		<div class="pic1">
			<img class="imgs" src="../../assets/images/公司简介背景.png" alt="">
			<div class="picBox">
				<div class="picBoxTop">
					<p class="p1">公司简介</p>
					<p class="p2" style="margin-left: -20px;">Company Profile</p>
				</div>
				<div class="picBoxBottom">
					<div class="leftBox">
						<p>
							软通动力信息技术（集团）股份有限公司在与辽宁省市区三级政府签署了合作协议后，将数十年的行业经验与专业能力植入辽宁，在辽宁落地全资子公司-辽宁软通智新科技有限公司（以下简称“辽宁软通智新”）。
						</p>
						<p>
							辽宁软通智新深入贯彻落实辽宁新发展理念，基于软通动力集团与省市区三级政府签署的合作协议，在辽宁以“一总部、三基地”即东北区域总部、数字能源产业基地、工业互联网产业基地、产教融合产业基地为整体战略规划，落地辽宁、扎根辽宁。同时进一步在辽宁落地“三中心两平台”，全方位投产建城，深耕业务发展，积极参与辽宁全面振兴新突破三年行动，助力辽宁省经济产业发展，实现产业数字化转型，辅以全域在2035年高质量建成数字辽宁、智造强省。
						</p>
					</div>
					<img src="../../assets/images/公司简介-图.png" alt="">
				</div>
			</div>
		</div>
		<div class="pic2" style="background: #f7f7f7;">
			<div class="picBox" >
				<div class="picBoxTop">
					<p class="p1">业务规划</p>
					<p class="p2">Business Planning</p>
				</div>
				<div class="picBoxCenter">
					<div class="picBoxCenter01">
						<span class="title1">01</span><span  class="title2">生态渠道汇聚，构筑辽宁新高地</span>
					</div>
					<p class="picBoxCenter02" style="margin-top: 30px;">
						辽宁软通智新落地辽宁，联动<span>国网辽宁公司、国电投、移动公司</span>，并发挥头部企业的带动作用，以商招商，协同国家发改委小城镇中心智慧城市研究所、国能辽宁公司、奇安信、华润物业、华润能源、徐工集团等伙伴，同时带动核心民生央企工业和信息化部电子第一、第五研究所落户辽宁投资建设发展。立足省会城市<span>沈阳，</span>协同<span>大连、鞍山、营口、锦州、铁岭</span>重点城市率先发力。
					</p>
					<p class="picBoxCenter02">
						辽宁软通智新与众业内顶级头部企业（院所）汇聚辽宁，携手联动，围绕<span>数字能源、工业互联网、产教融合</span>等重点领域多项布局。构建辽宁新生态，为辽宁的建设注入新的生机活力，助力辽宁的发展迈向新台阶。
					</p>
					<img style="width: 100%;margin: 50px 0;" src="../../assets/images/编组.png" alt="">
				</div>
			</div>
		</div>
		<div class="pic2">
			<div class="picBox" style="padding-top: 50px;">
				<!-- <div class="picBoxTop">
					<p class="p1">业务规划</p>
					<p class="p2">Business Planning</p>
				</div> -->
				<div class="picBoxCenter">
					<div class="picBoxCenter01">
						<span class="title1">02</span><span  class="title2">以辽宁为中心，向东北、华北地区逐步扩展</span>
					</div>
					<div class="picBoxCenter02">
						辽宁软通智新未来业务开展以<span>辽宁为根据地，面向东北和华北地区，</span>充分利用地理位置的优势，依托资源优势和产业优势，深化市场开发，提升品牌影响力，实现业务的快速增长。坚持以客户需求为导向，以优质的服务和专业的团队赢得客户的信任和支持，推动数字能源、工业互联网、产教融合、AI大模型等业务在东北、华北地区的持续深耕。
					</div>
					<img style="margin-left: 80px;" src="../../assets/images/中国地图.png" alt="">
					<div class="picBoxCenter03">
						<div class="picBoxCenter03Item">
							<div class="picBoxCenterImgP">
								<img src="../../assets/images/全面推进产业发展.png" alt="">
								<div class="picBoxCenterP">
									<p>全面推进产业发展</p>
								</div>
							</div>
							<div class="title">
								推动数字经济和实体经济融合发展，加快培育数字经济领军企业，推进软件、工业互联网等数字产业集群建设，发展新型能源供给、碳达峰与碳中和、绿色制造等绿色产业
							</div>
						</div>
						<div class="picBoxCenter03Item">
							
							<div class="picBoxCenterImgP">
								<img src="../../assets/images/贯彻创新发展理念.png" alt="">
								<div class="picBoxCenterP">
									<p>贯彻创新发展理念</p>
								</div>
							</div>
							<div class="title">
								辽宁软通智新将联合上下游高端头部生态，共同在东北、华北地区持续发力，致力于贯彻新发展理念，把科技创新作为振兴发展的战略支撑，开展业务应用创新，做活数字政府建设生态
							</div>
						</div>
						<div class="picBoxCenter03Item">
							<div class="picBoxCenterImgP">
								<img src="../../assets/images/加强与合作伙伴的联动.png" alt="">
								<div class="picBoxCenterP">
									<p>加强与合作伙伴的联动</p>
								</div>
							</div>
							<div class="title">
								辽宁软通智新与众业内顶级头部企业（院所）携手联动，布局东北、华北地区，构建新生态，注入新的生机活力
							</div>
						</div>
						<div class="picBoxCenter03Item">
							<div class="picBoxCenterImgP">
								<img src="../../assets/images/扬长补短发挥各地优势.png" alt="">
								<div class="picBoxCenterP">
									<p>扬长补短发挥各地优势</p>
								</div>
							</div>
							<div class="title">
								以辽宁软通智新为龙头、配合各合作伙伴的优势，整合东北、华北地区内各地的优势，吸引更多具有潜力的高端服务业企业，共建面向未来的一体化综合中心，积极吸引全国各省领导参观考察，共同打造面向高端服务业的东北、华北第一品牌
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pic3" style="background: #f7f7f7;padding-bottom: 80px;">
			<div class="picBox">
				<div class="picBoxCenter01">
					<div> </div>
					<div class="right">
						<span  class="title1">辽宁软通智新在辽发展整体业务介绍</span><span class="title2">03</span>
					</div>
				</div>
				<img style="margin-right: 30px;" src="../../assets/images/辽宁软通在辽发展整体业务介绍.png" alt="">
			</div>
		</div>
		<div class="pic4">
			<img class="imgs" src="../../assets/images/先期核心发展：“一总部三基地”背景.png" alt="">
			<div class="picBox">
				<div class="picBoxCenter01">
					<span class="title1">04</span><span  class="title2">先期核心发展：“一总部三基地”</span>
				</div>
				<div class="picBoxCenter02">
					辽宁软通智新发挥软件和信息技术服务优势，及数字经济领军企业影响力，在辽宁布局建设<span>“一总部三基地”</span>，助力辽宁省做大做强软件、工业互联网等一批数字产业，推动绿色经济发展，构建绿色产业体系。
				</div>
				<img src="../../assets/images/先期核心发展：“一总部三基地”.png" alt="">
			</div>
		</div>
		<div class="bottomBox">
			<div class="boxTop">
				<div class="leftItem1">
					<div class="itemTop">
						<span class="span1">联系我们</span><span class="span2">Contact us</span>
					</div>
					<p class="p1" style="margin-top: 40px;">客服电话：024-66189888</p>
					<p class="p1">公司邮箱：LNZX@isoftstone.com</p>
					<p class="p1" style="height: 20px;">公司地址：辽宁省沈阳市沈河区青年</p>
					<p class="p1" style="margin-left: 70px;">大街268-1号2101</p>
				</div>
				<div class="leftItem2">
					<div class="itemTop">
						<span class="span1">快捷导航</span><span class="span2">Quick Navigation</span>
					</div>
					<div class="itemBottom">
						<div class="itemBottomLeft">
							<p @click="getHomePages">首页</p>
							<p @click="getAbouts">关于我们</p>
							<p @click="getContactUs">联系我们</p>
						</div>
						<div class="itemBottomRight">
							<p @click="getGreenEnergy">绿色能源板块</p>
							<p @click="getIoTSectors">工业物联网板块</p>
						</div>
					</div>
				</div>
				<div class="leftItem3">
					<div class="itemTop">
						<span class="span1">关注我们</span><span class="span2">Follow us</span>
					</div>
					<div class="right">
						<div class="rightItem" style="margin-right: 20px;">
							<img src="../../assets/images/ios下载码.png" alt="">
							<p>扫描下载通通APP（IOS）</p>
						</div>
						<div class="rightItem">
							<img src="../../assets/images/android下载码.png" alt="">
							<p>扫描下载通通APP（Android)</p>
						</div>
					</div>
				</div>
			</div>
			<div class="boxBottom">
				<div class="lefts">
					<div class="leftC">c</div>
					<p>辽宁软通智新科技有限公司版权所有</p>
				</div>
        <a class="filingNumber" style="margin: 0 50px;"  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2023010593号-2</a>
        <div class="lefts">
					<img src="../../assets/images/备案图标.png" alt="">
					<p style="margin: 3px 0 0 10px;">辽公网安备21010302666676号</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	mounted() {
		this.scrollToTop();
	},
	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		getHomePages() {
			this.$emit('jumpToPage', '1' )
		},
		getAbouts() {
			this.$emit('jumpToPage', '3' )
		},
		getContactUs() {
			this.$emit('jumpToPage', '4' )
		},
		getGreenEnergy() {
			this.$emit('jumpToPage', '2-1' )
		},	
		getIoTSectors() {
			this.$emit('jumpToPage', '2-2' )
		}
	}
}
</script>

<style lang="less" scoped>
.homePage {
	height: 100%;
	.picture {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
		}
		.picturer {
			position: absolute;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%);
			.p1 {
				width: 1006px;
				height: 66px;
				font-family: SourceHanSansCN-Bold;
				font-size: 66px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				font-weight: 700;
				margin-bottom: 30px;
			}
			.p2 {
				// width: 940px;
				height: 40px;
				font-family: SourceHanSansCN-Normal;
				font-size: 40px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				line-height: 40px;
				font-weight: 400;
				margin-left: -20px;
			}
		}
	}
		
	.newsBottom {
		width: 100%;
		height: 900px;
		display: flex;
		align-items: center;
		flex-direction: column;
		background: #fafafa;
		.title {
			margin: 55px 0;
			.news {
				width: 144px;
				height: 50px;
				font-family: PingFangSC-Semibold;
				font-size: 36px;
				color: #222222;
				letter-spacing: 0;
				// text-align: center;
				font-weight: 600;
			}
			.new {
				// width: 200px;
				height: 24px;
				font-family: ArialMT;
				font-size: 24px;
				color: #666666;
				margin-left: -40px;
				letter-spacing: 0;
				// text-align: center;
				line-height: 24px;
				font-weight: 400;
			}
		}
		.images {
			display: flex;
			.characters {
				width: 500px;
				height: 320px;
				font-family: PingFangSC-Regular;
				font-size: 18px;
				color: #222222;
				letter-spacing: 0;
				line-height: 32px;
				font-weight: 400;
				margin: 50px 50px 0 0;
			}
		}
		
	}
	.pic1 {
		width: 100%;
		// height: 900px;
		position: relative;
		background: #fff;
		font-size: 0px;
		.imgs {
			width: 100%;
			height: 100%;
		}
		.picBox {
			width: 66.6%;
			// height: 820px;
			position: absolute;
			background: #fff;
			padding: 0 20px;
			top: 0;
			left: 16.6%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.picBoxTop {
				.p1 {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					text-align: center;
					font-weight: 600;
					margin: 66px 0 5px 0;
				}
				.p2 {
					// width: 193px;
					margin: 0 0 60px -30px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					letter-spacing: 0;
					text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.picBoxBottom {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.leftBox {
					width: 48%;
					padding-top: 150px;
					p {
						text-indent:2em;
						font-family: PingFangSC-Regular;
						font-size: 20px;
						color: #222222;
						letter-spacing: 0;
						line-height: 32px;
						font-weight: 400;
					}
				}
				img{
					width: 45%;
				}
			}
		}
	}
	.pic2 {
		width: 100%;
		display: flex;
		justify-content: center;
		.picBox {
			width: 66.6%;
			// height: 900px;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 30px 0;
			.picBoxTop {
				.p1 {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					text-align: center;
					font-weight: 600;
					margin: 66px 0 5px 0;
				}
				.p2 {
					// width: 193px;
					margin: 0 0 60px -30px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					letter-spacing: 0;
					text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.picBoxCenter {
				.picBoxCenter01 {
					.title1 {
						width: 58px;
						height: 60px;
						opacity: 0.4;
						font-family: DINAlternate-Bold;
						font-size: 60px;
						color: #008BD1;
						letter-spacing: 0;
						text-align: center;
						line-height: 60px;
						font-weight: 700;
						margin-right: 20px;
					}
					.title2 {
						width: 684px;
						height: 100px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						font-weight: 600;
					}
				}
				.picBoxCenter02 {
					// text-indent:2em;
					margin: 20px 0 0 95px;
					width: 1152px;
					font-family: PingFangSC-Regular;
					font-size: 20px;
					color: #666666;
					letter-spacing: 0;
					text-align: justify;
					line-height: 28px;
					font-weight: 400;
					span {
						color: #333;
						font-weight: 600;
						margin: 0 3px;
					}
				}
				.picBoxCenter03 {
					display: flex;
					margin-top: 20px;
					.picBoxCenter03Item {
						width: 305px;
						height: 520px;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-right: 20px;
						.picBoxCenterImgP {
							position: relative;
							.picBoxCenterP {
								position: absolute;
								bottom: 20px;
								left: 50%;
								transform: translate(-50%, 0);
								p {
									width: 160px;
									height: 16px;
									font-family: PingFangSC-Semibold;
									font-size: 16px;
									color: #FFFFFF;
									letter-spacing: 0;
									text-align: center;
									line-height: 16px;
									font-weight: 600;
								}
							}
						}
						.title {
							margin-top: 30px;
							width: 285px;
							height: 137px;
							font-family: PingFangSC-Regular;
							font-size: 16px;
							color: #666666;
							letter-spacing: 0;
							line-height: 28px;
							font-weight: 400;
						}
					}
				}
			}
			.picBoxBottom {
				width: 100%;
				img{
					width: 45%;
				}
			}
		}
	}
	.pic3 {
		width: 100%;
		display: flex;
		justify-content: center;
		.picBox {
			width: 66.6%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.picBoxCenter01 {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin: 60px 0;
				.right{
					.title1 {
						width: 504px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						text-align: right;
						font-weight: 600;
						margin-right: 20px;
					}
					.title2 {
						width: 58px;
						height: 60px;
						opacity: 0.4;
						font-family: DINAlternate-Bold;
						font-size: 60px;
						color: #008BD1;
						letter-spacing: 0;
						text-align: center;
						line-height: 60px;
						font-weight: 700;
					}
				}
			}
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.pic4 {
		width: 100%;
		position: relative;
		background: #fff;
		// height: 1250px;
		font-size: 0px;
		.imgs {
			width: 100%;
			height: 100%;
		}
		.picBox {
			width: 66.6%;
			// height: 1150px;
			position: absolute;
			background: #fff;
			padding: 20px;
			top: 0;
			left: 16.6%;
			.picBoxCenter01 {
				margin: 40px 0 40px 0;
				.title1 {
					width: 58px;
					height: 60px;
					opacity: 0.4;
					font-family: DINAlternate-Bold;
					font-size: 60px;
					color: #008BD1;
					letter-spacing: 0;
					text-align: center;
					line-height: 60px;
					font-weight: 700;
					margin-right: 20px;
				}
				.title2 {
					width: 684px;
					height: 100px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					font-weight: 600;
				}
			}
			img {
				width: 100%;
				height: 100%;
			}
			.picBoxCenter02 {
				// text-indent:2em;
				margin: 0 0 40px 93px;
				width: 88%;
				font-family: PingFangSC-Regular;
				font-size: 20px;
				color: #666666;
				letter-spacing: 0;
				text-align: justify;
				line-height: 28px;
				font-weight: 400;
				span {
					color: #333;
					font-weight: 600;
					margin: 0 3px;
				}
			}
		}
	}
	.bottomBox {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #f7f7f7;
		.boxTop {
			width: 66.6%;
			height: 440px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #EEE;
			.leftItem1 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.p1 {
					width: 234px;
					height: 40px;
					font-family: PingFangSC-Semibold;
					font-size: 14px;
					color: #999999;
					letter-spacing: 0;
					line-height: 20px;
					font-weight: 600;
				}
			}
			.leftItem2 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.itemBottom {
					width: 230px;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					p {
						cursor: pointer;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 14px;
						font-weight: 600;
					}
				}
			}
			.leftItem3 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.right {
					display: flex;
					margin-top: 50px;
					.rightItem {
						width: 200px;
						height: 240px;
						background: #fff;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-radius: 10px;
						p {
							width: 200px;
							height: 14px;
							font-family: PingFangSC-Regular;
							font-size: 14px;
							color: #222222;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 400;
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					}
				}
			}
			
		}
		.boxBottom {
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			.lefts {
				display: flex;
				.leftC {
					width: 11px;
					height: 11px;
					border-radius: 50%;
					border: 1px solid #333;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					color: #333333;
					letter-spacing: 0;
				}
			}
			p { 
				height: 14px;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
			.filingNumber {
				text-decoration: none;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
		}
	}
}
@media (max-width:1500px) { 
	.homePage {
		height: 100%;
		.picture {
			width: 100%;
			position: relative;
			img {
				width: 100%;
				height: 100%;
			}
			.picturer {
				position: absolute;
				left:50%;
				top:50%;
				transform: translate(-50%, -50%);
				.p1 {
					// width: 396px;
					height: 66px;
					font-family: SourceHanSansCN-Bold;
					font-size: 60px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					font-weight: 700;
					margin-bottom: 30px;
				}
				.p2 {
					// width: 740px;
					height: 40px;
					font-family: SourceHanSansCN-Normal;
					font-size: 35px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					line-height: 40px;
					font-weight: 400;
					margin-left: -20px;
				}
			}
		}
			
		.newsBottom {
			width: 100%;
			height: 900px;
			display: flex;
			align-items: center;
			flex-direction: column;
			background: #fafafa;
			.title {
				margin: 55px 0;
				.news {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					// text-align: center;
					font-weight: 600;
				}
				.new {
					// width: 200px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					margin-left: -40px;
					letter-spacing: 0;
					// text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.images {
				display: flex;
				.characters {
					width: 500px;
					height: 320px;
					font-family: PingFangSC-Regular;
					font-size: 18px;
					color: #222222;
					letter-spacing: 0;
					line-height: 32px;
					font-weight: 400;
					margin: 50px 50px 0 0;
				}
			}
			
		}
		.pic1 {
			width: 100%;
			height: 850px;
			position: relative;
			background: #fff;
			.imgs {
				width: 100%;
				height: 100%;
			}
			.picBox {
				width: 76.6%;
				// height: 730px;
				position: absolute;
				background: #fff;
				padding: 0 20px;
				top: 2%;
				left: 11.6%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.picBoxTop {
					.p1 {
						width: 144px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						text-align: center;
						font-weight: 600;
						margin: 66px 0 5px 0;
					}
					.p2 {
						// width: 193px;
						margin: 0 0 60px -30px;
						height: 24px;
						font-family: ArialMT;
						font-size: 24px;
						color: #666666;
						letter-spacing: 0;
						text-align: center;
						line-height: 24px;
						font-weight: 400;
					}
				}
				.picBoxBottom {
					width: 100%;
					.leftBox {
						width: 48%;
						padding-top: 80px;
						p {
							// text-indent:2em;
							font-family: PingFangSC-Regular;
							font-size: 18px;
							color: #222222;
							letter-spacing: 0;
							line-height: 32px;
							font-weight: 400;
						}
					}
					img{
						width: 45%;
					}
				}
			}
		}
		.pic2 {
			width: 100%;
			display: flex;
			justify-content: center;
			.picBox {
				width: 76.6%;
				// height: 900px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.picBoxTop {
					.p1 {
						width: 144px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						text-align: center;
						font-weight: 600;
						margin: 26px 0 5px 0;
					}
					.p2 {
						// width: 193px;
						margin: 0 0 20px -30px;
						height: 24px;
						font-family: ArialMT;
						font-size: 24px;
						color: #666666;
						letter-spacing: 0;
						text-align: center;
						line-height: 24px;
						font-weight: 400;
					}
				}
				.picBoxCenter {
					.picBoxCenter01 {
						.title1 {
							width: 58px;
							height: 60px;
							opacity: 0.4;
							font-family: DINAlternate-Bold;
							font-size: 50px;
							color: #008BD1;
							letter-spacing: 0;
							text-align: center;
							line-height: 60px;
							font-weight: 700;
							margin-right: 20px;
						}
						.title2 {
							width: 684px;
							height: 100px;
							font-family: PingFangSC-Semibold;
							font-size: 30px;
							color: #222222;
							letter-spacing: 0;
							font-weight: 600;
						}
					}
					.picBoxCenter02 {
						// text-indent:2em;
						margin: 20px 0 0 85px;
						width: 1010px;
						font-family: PingFangSC-Regular;
						font-size: 18px;
						color: #666666;
						letter-spacing: 0;
						text-align: justify;
						line-height: 28px;
						font-weight: 400;
						span {
							color: #333;
							font-weight: 600;
							margin: 0 3px;
						}
					}
					.picBoxCenter03 {
						display: flex;
						margin-top: 20px;
						.picBoxCenter03Item {
							width: 250px;
							height: 520px;
							display: flex;
							flex-direction: column;
							align-items: center;
							margin-right: 20px;
							img {
								width: 250px;
								height: 230px;
							}
							.title {
								margin-top: 30px;
								width: 245px;
								// height: 137px;
								font-family: PingFangSC-Regular;
								font-size: 16px;
								color: #666666;
								letter-spacing: 0;
								line-height: 28px;
								font-weight: 400;
							}
						}
					}
				}
				.picBoxBottom {
					width: 100%;
					img{
						width: 45%;
					}
				}
			}
		}
		.pic3 {
			width: 100%;
			display: flex;
			justify-content: center;
			.picBox {
				width: 76.6%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.picBoxCenter01 {
					width: 100%;
					display: flex;
					justify-content: space-between;
					margin: 60px 0;
					.right{
						.title1 {
							width: 504px;
							height: 50px;
							font-family: PingFangSC-Semibold;
							font-size: 30px;
							color: #222222;
							letter-spacing: 0;
							text-align: right;
							font-weight: 600;
							margin-right: 20px;
						}
						.title2 {
							width: 58px;
							height: 60px;
							opacity: 0.4;
							font-family: DINAlternate-Bold;
							font-size: 50px;
							color: #008BD1;
							letter-spacing: 0;
							text-align: center;
							line-height: 60px;
							font-weight: 700;
						}
					}
				}
				img {
					width: 1080px;
					height: 625px;
				}
			}
		}
		.pic4 {
			width: 100%;
			position: relative;
			background: #fff;
			height: 1050px;
			.imgs {
				width: 100%;
				height: 100%;
			}
			.picBox {
				width: 76.6%;
				// height: 970px;
				position: absolute;
				background: #fff;
				top: 2%;
				left: 11.6%;
				// display: flex;
				// flex-direction: column;
				// align-items: center;
				.picBoxCenter01 {
					margin: 50px 0 30px 0;
					.title1 {
						width: 58px;
						height: 50px;
						opacity: 0.4;
						font-family: DINAlternate-Bold;
						font-size: 50px;
						color: #008BD1;
						letter-spacing: 0;
						text-align: center;
						line-height: 60px;
						font-weight: 700;
						margin-right: 20px;
					}
					.title2 {
						width: 684px;
						height: 100px;
						font-family: PingFangSC-Semibold;
						font-size: 30px;
						color: #222222;
						letter-spacing: 0;
						font-weight: 600;
					}
				}
				.picBoxCenter02 {
					// text-indent:2em;
					margin: 10px 0 30px 80px;
					width: 91%;
					font-family: PingFangSC-Regular;
					font-size: 18px;
					color: #666666;
					letter-spacing: 0;
					text-align: justify;
					line-height: 28px;
					font-weight: 400;
				}
				img {
					width: 100%;
					height: 710px;
				}
			}
		}
		.bottomBox {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #f7f7f7;
			.boxTop {
				width: 76.6%;
				height: 440px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #EEE;
				.leftItem1 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.p1 {
						width: 234px;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 20px;
						font-weight: 600;
					}
				}
				.leftItem2 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.itemBottom {
						width: 230px;
						margin-top: 40px;
						display: flex;
						justify-content: space-between;
						p {
							cursor: pointer;
							height: 40px;
							font-family: PingFangSC-Semibold;
							font-size: 14px;
							color: #999999;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 600;
						}
					}
				}
				.leftItem3 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.right {
						display: flex;
						margin-top: 50px;
						.rightItem {
							width: 200px;
							height: 240px;
							background: #fff;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							border-radius: 10px;
							p {
								width: 200px;
								height: 14px;
								font-family: PingFangSC-Regular;
								font-size: 14px;
								color: #222222;
								letter-spacing: 0;
								line-height: 14px;
								font-weight: 400;
								display: flex;
								flex-direction: column;
								align-items: center;
							}
						}
					}
				}
				
			}
			.boxBottom {
				width: 100%;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				.lefts {
					display: flex;
					.leftC {
						width: 11px;
						height: 11px;
						border-radius: 50%;
						border: 1px solid #333;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #333333;
						letter-spacing: 0;
					}
				}
				p { 
					height: 14px;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
				.filingNumber {
					text-decoration: none;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
			}
		}
	}
}
</style>