var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homePage"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"bottomBox"},[_c('div',{staticClass:"boxTop"},[_vm._m(6),_c('div',{staticClass:"leftItem2"},[_vm._m(7),_c('div',{staticClass:"itemBottom"},[_c('div',{staticClass:"itemBottomLeft"},[_c('p',{on:{"click":_vm.getHomePages}},[_vm._v("首页")]),_c('p',{on:{"click":_vm.getAbouts}},[_vm._v("关于我们")]),_c('p',{on:{"click":_vm.getContactUs}},[_vm._v("联系我们")])]),_c('div',{staticClass:"itemBottomRight"},[_c('p',{on:{"click":_vm.getGreenEnergy}},[_vm._v("绿色能源板块")]),_c('p',{on:{"click":_vm.getIoTSectors}},[_vm._v("工业物联网板块")])])])]),_vm._m(8)]),_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":require("../../assets/images/banner4.png"),"alt":""}}),_c('div',{staticClass:"picturer"},[_c('p',{staticClass:"p1"},[_vm._v("拥抱绿色能源")]),_c('p',{staticClass:"p2"},[_vm._v("打造低碳经济发展新模式")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newsBottom"},[_c('img',{staticClass:"imgs",attrs:{"src":require("../../assets/images/绿色能源背景.png"),"alt":""}}),_c('div',{staticClass:"newsBottomBox"},[_c('div',{staticClass:"title"},[_c('p',{staticClass:"news"},[_vm._v("绿色能源")]),_c('p',{staticClass:"new"},[_vm._v("Green Energy")])]),_c('div',{staticClass:"images"},[_c('div',{staticClass:"characters",staticStyle:{"text-indent":"2em"}},[_vm._v(" 坚持以习近平新时代中国特色社会主义思想为指导，加快生态文明建设、推动经济转型，关系人民福祉，关乎民族未来，事关“两个一百年”奋斗目标和中华民族伟大复兴的实现。辽宁软通智新怀着强烈的责任感与使命感成为“碳达峰、碳中和”战略落实的推动者和贡献者，“碳”寻未来新发展，敢为数字科技与能源技术融合创新之人先，担当能源企业数字化转型的护航者。辽宁软通智新始终坚持数字技术对绿色经济的赋能，专注碳达峰碳中和技术创新和研发，以科技创新推动绿色发展，助力客户实现低碳战略下的数字化转型，贯彻落实“双碳”目标的实现。 ")]),_c('img',{attrs:{"src":require("../../assets/images/绿色能源图.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic1"},[_c('div',{staticClass:"pic1Box"},[_c('div',{staticClass:"pic1BoxTop"},[_c('div',{staticClass:"pic1BoxTopTitle"},[_c('span',{staticClass:"title1"},[_vm._v("01")]),_c('span',{staticClass:"title2"},[_vm._v("虚拟电厂运营管理解决方案")])]),_c('div',{staticClass:"pic1BoxTopTitle2"},[_c('p',{staticClass:"title3"},[_vm._v("虚拟电厂（Virtual Power Plant，简称VPP）指的是通过整合多个分布式能源资源（如风力发电、太阳能发电、储能系统等）并进行智能管理和运营的电力系统。虚拟电厂通过互联网、物联网和智能电网技术等手段将分散的能源资源、负荷需求和储能系统进行集成，形成一个虚拟的、统一管理的电力系统。")]),_c('p',{staticClass:"title5"},[_c('span',[_vm._v("1. 多源能源整合：")]),_vm._v("将分散的可再生能源、储能系统等能源资源通过互联网等技术进行连接整合，实现能源的灵活调度和优化控制。")]),_c('p',{staticClass:"title5"},[_c('span',[_vm._v("2. 智能电网管理：")]),_vm._v("利用物联网、人工智能和大数据等技术，实现对虚拟电厂的集中监控、调度和运营管理，提高系统的可靠性、灵活性和效率。")]),_c('p',{staticClass:"title5"},[_c('span',[_vm._v("3. 能源市场参与：")]),_vm._v("虚拟电厂可以参与能源市场，通过电力销售、电力交易等方式实现能源的优化利用和经济效益最大化。")]),_c('p',{staticClass:"title5"},[_c('span',[_vm._v("4. 网络协同运营：")]),_vm._v("虚拟电厂能够与传统电力系统、用户侧负荷、电网企业等进行网络协同运营，实现能源的有序调度和资源的共享利用。")])])]),_c('img',{attrs:{"src":require("../../assets/images/虚拟电厂运营管理解决方案图.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic2"},[_c('img',{staticClass:"imgs",attrs:{"src":require("../../assets/images/背景图2.png"),"alt":""}}),_c('div',{staticClass:"pic2Box"},[_c('h1',[_vm._v("虚拟电厂-N个用能聚合场景板块-三大目标”的运营模式应时而生")]),_c('h3',[_vm._v("集智能管理调度、电力本地高频消纳、双碳场景支撑能力与一身的建设绿色双碳数字能源综合体系")]),_c('img',{attrs:{"src":require("../../assets/images/虚拟电厂-N个用能聚合场景板块-三大目标图.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic3"},[_c('div',{staticClass:"pic1Box"},[_c('div',{staticClass:"pic1BoxTopTitle"},[_c('span',{staticClass:"title1",staticStyle:{"margin-right":"10px"}},[_vm._v("02")]),_c('span',{staticClass:"title2"},[_vm._v("充换电服务解决方案")])]),_c('p',{staticClass:"title3"},[_vm._v("通通充换电服务平台，是基于辽宁软通智新物联网技术和综合能源服务平台构建的一体化运营管理平台，统一监控后台通过对充电桩、充电机及配电系统数据的采集存储、处理分析，提供图形化人机监控界面，实现智能充电、保障供电系统安全、提升监控人员工作效率，从而确保充电设施安全、可靠、经济运行，满足充电桩服务商、用户及三方监管机构的需求。 ")]),_c('video',{attrs:{"controls":""}},[_c('source',{attrs:{"src":require("../../assets/video.mp4"),"type":"video/mp4"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic4",staticStyle:{"background":"#F7F7F7"}},[_c('div',{staticClass:"pic1Box"},[_c('div',{staticClass:"pic1BoxTopTitle"},[_c('span',{staticClass:"title1",staticStyle:{"margin-right":"10px"}},[_vm._v("03")]),_c('span',{staticClass:"title2"},[_vm._v("风光储充一体化综合能源解决方案")])]),_c('p',{staticClass:"title3"},[_vm._v("风光储充一体化运营管控平台，包含风电、光伏发电系统、储能逆变系统、电动汽车充电系统、智慧照明系统、安防监控系统等。与传统的解决方案相比，将新能源光伏发电、汽车充电、电池储能、节能技术整合到一起，通过物联网平台进行互联互通，实时监控能量流动，集中监控、智能管理。 ")]),_c('p',{staticClass:"title3",staticStyle:{"margin-top":"-30px"}},[_vm._v(" 风光储充一体化运营管控平台利用大云物移智等前沿技术，围绕分布式电源 “可观、可测、可控、可调”核心目标，融合电网营销业务应用、用电信息采集、调度OMS等系统数据，为用户提供丰富的应用场景，如新能源全景看板、新能源在线监测、新能源远程控制、新能源发电预测、新能源智能调度、新能源市场运营、新能源并网管理等一站式服务。以“服务发电企业”为宗旨，持续挖掘光伏、水电、风电等分布式电源调节潜能，提升新能源消纳能力，立足构建新型电力系统，服务碳达峰、碳中和目标大局，全力保障电网高质量发展。 ")]),_c('img',{attrs:{"src":require("../../assets/images/风光储充一体化综合能源解决方案图.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftItem1"},[_c('div',{staticClass:"itemTop"},[_c('span',{staticClass:"span1"},[_vm._v("联系我们")]),_c('span',{staticClass:"span2"},[_vm._v("Contact us")])]),_c('p',{staticClass:"p1",staticStyle:{"margin-top":"40px"}},[_vm._v("客服电话：024-66189888")]),_c('p',{staticClass:"p1"},[_vm._v("公司邮箱：LNZX@isoftstone.com")]),_c('p',{staticClass:"p1",staticStyle:{"height":"20px"}},[_vm._v("公司地址：辽宁省沈阳市沈河区青年")]),_c('p',{staticClass:"p1",staticStyle:{"margin-left":"70px"}},[_vm._v("大街268-1号2101")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itemTop"},[_c('span',{staticClass:"span1"},[_vm._v("快捷导航")]),_c('span',{staticClass:"span2"},[_vm._v("Quick Navigation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftItem3"},[_c('div',{staticClass:"itemTop"},[_c('span',{staticClass:"span1"},[_vm._v("关注我们")]),_c('span',{staticClass:"span2"},[_vm._v("Follow us")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"rightItem",staticStyle:{"margin-right":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/ios下载码.png"),"alt":""}}),_c('p',[_vm._v("扫描下载通通APP（IOS）")])]),_c('div',{staticClass:"rightItem"},[_c('img',{attrs:{"src":require("../../assets/images/android下载码.png"),"alt":""}}),_c('p',[_vm._v("扫描下载通通APP（Android)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxBottom"},[_c('div',{staticClass:"lefts"},[_c('div',{staticClass:"leftC"},[_vm._v("c")]),_c('p',[_vm._v("辽宁软通智新科技有限公司版权所有")])]),_c('a',{staticClass:"filingNumber",staticStyle:{"margin":"0 50px"},attrs:{"target":"_blank","href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v("辽ICP备2023010593号-2")]),_c('div',{staticClass:"lefts"},[_c('img',{attrs:{"src":require("../../assets/images/备案图标.png"),"alt":""}}),_c('p',{staticStyle:{"margin":"3px 0 0 10px"}},[_vm._v("辽公网安备21010302666676号")])])])
}]

export { render, staticRenderFns }