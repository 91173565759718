<template>
    <div class="about">
      <header class="header">
        <img src="../assets/通通logo1.png" alt="">
        <div class="flag"></div>
        <p>账号与安全</p>
      </header>
      <div class="content">
        <div class="contentBox">
          <el-form :model="ruleForm" ref="ruleForm" label-width="110px" class="demo-ruleForm">
            <el-form-item label="当前账户：">
              <el-input v-model="ruleForm.name" placeholder="请输入账户名"></el-input>
            </el-form-item>
            <el-form-item label="手机绑定：">
              <el-input v-model="ruleForm.number" placeholder="请输入手机号码">
								<el-button @click="replace" style="margin: 0 30px 0 0; color: #2C57FF;" slot="suffix" type="text">更换绑定</el-button>
							</el-input>
            </el-form-item>
            <el-form-item label="更换登录密码：">
              <el-input v-model="ruleForm.date1" placeholder="请输入登录密码">
								<el-button @click="changeLoginPassword" style="margin: 0 30px 0 0; color: #2C57FF;" slot="suffix" type="text">更换登录密码</el-button>
							</el-input>
            </el-form-item>
            <el-form-item label="更换支付密码：" >
              <el-input v-model="ruleForm.date2" placeholder="请输入支付密码">
								<el-button @click="changePaymentPassword" style="margin: 0 30px 0 0; color: #2C57FF;" slot="suffix" type="text">更换支付密码</el-button>
							</el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
			<el-dialog
				title="修改手机号"
				:visible.sync="dialogVisible"
				width="30%"
			>
				<span>为保护您的帐号安全，需要对现在绑定的手机号进行短信验证</span>
				<div>
					<el-input v-model="phone" style="margin: 10px 0;" placeholder="请输入手机号码">
						<template slot="prepend">+86</template>
					</el-input>
					<el-input v-model="number" placeholder="请输入验证码">
						<el-button style="margin: 0 20px 0 0; color: #2C57FF;" slot="suffix" type="text">获取验证码</el-button>
					</el-input>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisible = false">下一步</el-button>
				</span>
			</el-dialog>
			<el-dialog
				title="身份验证"
				:visible.sync="dialogVisible1"
				width="30%"
			>
				<div>
					<el-input v-model="phone1" style="margin: 10px 0;" placeholder="请输入手机号码">
						<template slot="prepend">+86</template>
					</el-input>
					<el-input v-model="number1" placeholder="请输入验证码">
						<el-button style="margin: 0 20px 0 0; color: #2C57FF;" slot="suffix" type="text">获取验证码</el-button>
					</el-input>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible1 = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisible1 = false">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog
				title="身份验证"
				:visible.sync="dialogVisible2"
				width="30%"
			>
				<div>
					<el-input v-model="phone2" style="margin: 10px 0;" placeholder="请输入手机号码">
						<template slot="prepend">+86</template>
					</el-input>
					<el-input v-model="number2" placeholder="请输入验证码">
						<el-button style="margin: 0 20px 0 0; color: #2C57FF;" slot="suffix" type="text">获取验证码</el-button>
					</el-input>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible2 = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
				</span>
			</el-dialog>
			<!-- <el-dialog
				title="修改登录密码"
				:visible.sync="dialogVisible1"
				width="30%"
			>
				<el-form :model="ruleForm1" :rules="rules1" ref="ruleForms" label-width="120px" class="demo-ruleForm">
					<el-form-item prop="name" label="原登录密码">
						<el-input v-model="ruleForm1.name"  placeholder="请输入原登录密码"></el-input>
					</el-form-item>
					<el-form-item prop="pass" label="新登录密码">
						<el-input v-model="ruleForm1.pass" placeholder="请输入新登录密码"></el-input>
					</el-form-item>
					<el-form-item prop="newpass" label="确认新登录密码">
						<el-input v-model="ruleForm1.newpass" placeholder="请再次输入新登录密码"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible1 = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisible1 = false">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog
				title="修改支付密码"
				:visible.sync="dialogVisible2"
				width="30%"
			>
				<el-form :model="ruleForm2" :rules="rules2" ref="ruleForms" label-width="120px" class="demo-ruleForm">
					<el-form-item prop="name" label="原支付密码">
						<el-input v-model="ruleForm2.name" placeholder="请输入原支付密码"></el-input>
					</el-form-item>
					<el-form-item prop="pass" label="新支付密码">
						<el-input v-model="ruleForm2.pass" placeholder="请输入新支付密码"></el-input>
					</el-form-item>
					<el-form-item prop="newpass" label="确认新支付密码">
						<el-input v-model="ruleForm2.newpass" placeholder="请再次输入新支付密码"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible2 = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
				</span>
			</el-dialog> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'aboutPage',
    props: {
      
    },
    data() {
      return {
        ruleForm: {
          name: '', //tongtonghuandian
          number: '', //191****8934
          date1: '******', //
          date2: '******', //
          delivery: '',
          type: [],
          resource: '',
          desc: ''
        },
				ruleForm1: {},
				ruleForm2: {},
				dialogVisible: false,
				dialogVisible1: false,
				dialogVisible2: false,
				phone: '', //191****8934
				number: '',
				phone1: '', //191****8934
				number1: '',
				phone2: '', //191****8934
				number2: '',
        rules1: {
          name: [
            { required: true, message: '请输入原登录密码', trigger: 'blur' },
          ],
          pass: [
            { required: true, message: '请输入新登录密码', trigger: 'blur' }
          ],
          newpass: [
            { type: 'date', required: true, message: '请输再次输入新登录密码', trigger: 'blur' }
          ],
        },
        rules2: {
          name: [
            { required: true, message: '请输入原支付密码', trigger: 'blur' },
          ],
          pass: [
            { required: true, message: '请输入新支付密码', trigger: 'blur' }
          ],
          newpass: [
            { type: 'date', required: true, message: '请输再次输入新支付密码', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      jumpGo() {
        this.$router.push({
          path: '/login'
        })
      },
			replace() {
				this.dialogVisible = true;
			},
			changeLoginPassword() {
				this.dialogVisible1 = true;
			},
			changePaymentPassword() {
				this.dialogVisible2 = true;
			}
    }
  }
  </script>
  
  <style lang="less" scoped>
  /deep/.el-button {
    // width: 40%;
    // height: 50px;
  }
  .about {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .header {
      width: 100%;
      height: 100px;
      padding: 0 190px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      img {
        width: 162px;
        height: 52px;
      }
      .flag {
        width: 1px;
        height: 20px;
        background: #CCCCCC;
        margin: 0 10px;
      }
      p {
        height: 24px;
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        text-align: right;
        line-height: 20px;
        font-weight: 600;
      }
    }
    .content {
      width: 100%;
      background: #F5F6FA;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      .contentBox {
        width: 600px;
        height: 400px;
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 50px 300px;
        .checkbox {
          display: flex;
          margin-top: 10px;
          .read {
            margin: 12px 0 0 7px;
            width: 112px;
            height: 16px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
          }
          .policy {
            cursor: pointer; 
            margin-top: 12px;
            width: 192px;
            height: 16px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #2C57FF;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
          }
        }
        .buttons {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  
  </style>
  