<template>
	<div class="homePage">
		<div class="picture">
			<img src="../../assets/images/banner.png" alt="">
			<div class="picturer">
				<p class="p1">用数字技术赋能 助力客户价值提升</p>
				<p class="p2">能源—服务四大发电集团、两大电网公司</p>
				<div class="p3" @click="clickPageChange">
					<p>READ MORE</p>
					<div>→</div>
				</div>
			</div>
		</div>
		<!-- <div class="newsTop">
			<div class="newsTopItem" style="box-shadow: 0 0 5px 5px #f7f7f7;">
				<img src="../../assets/images/通讯设备.png" alt="">
				<div class="newsTopItemRight">
					<p class="device">通讯设备</p>
					<p class="service">连续十年为华为服务</p>
				</div>
			</div>
			<div class="newsTopItem">
				<img src="../../assets/images/互联网.png" alt="">
				<div class="newsTopItemRight">
					<p class="device">互联网服务</p>
					<p class="service">互联网巨头企业全覆盖</p>
				</div>
			</div>
			<div class="newsTopItem">
				<img src="../../assets/images/金融管理.png" alt="">
				<div class="newsTopItemRight">
					<p class="device">金融</p>
					<p class="service">服务大型银行、保险机构等</p>
				</div>
			</div>
			<div class="newsTopItem" style="box-shadow: 5px 5px 0 0 #f7f7f7;">
				<img src="../../assets/images/材料与能源.png" alt="">
				<div class="newsTopItemRight">
					<p class="device">高科技与制造</p>
					<p class="service">服务多家大型企业</p>
				</div>
			</div>
		</div> -->
		<div class="news">
			<div class="newsBottom">
				<div class="title">
					<p class="news">News</p>
					<p class="new">公司新闻</p>
				</div>
				<div class="pic">
					<a class="picItem" target="_blank" href="https://www.thepaper.cn/newsDetail_forward_26814135">
						<div class="picItemBottomimg">
							<img src="../../assets/images/公司新闻1.png" alt="">
						</div>
						<div class="picItemBottom">
							<div class="p1">吕志成在沈阳大学和科技企业调研时强调 充分释放科教潜能 加快培育振兴发展新动能新优势 </div>
							<div class="p2">沈阳政务 2024-03-26 08:33</div>
							<div class="p3">3月25日，市委副书记、市长吕志成到沈阳大学和辽宁软通智新科技有限公司等企业调研。他强调，要深入贯彻习近平总书记关于东北、辽宁全面振兴的重要讲话和指示批示精神，全面落实全国两…… </div>
						</div>
					</a>
					<a class="picItem" style="margin: 0 40px;" target="_blank" href="https://baijiahao.baidu.com/s?id=1785691352904953416&wfr=spider&for=pc">
						<div class="picItemBottomimg">
							<img src="../../assets/images/公司新闻2.png" alt="">
						</div>
						<div class="picItemBottom">
							<div class="p1">辽宁省首座新能源营运车辆储能换电站亮相沈阳</div>
							<div class="p2">软通动力 2023年10月18日 20:20 </div>
							<div class="p3">全自动更换汽车电池、90秒让车辆“满电复活”、司机无须下车便可享受极速换电体验……近日，辽宁省首座面向营运车辆的平急两用式储能换电站在沈阳市沈河区…… </div>
						</div>
					</a>
					<a class="picItem" target="_blank" href="https://baijiahao.baidu.com/s?id=1778059636401741922&wfr=spider&for=pc">
						<div class="picItemBottomimg">
							<img src="../../assets/images/公司新闻3.png" alt="">
						</div>
						<div class="picItemBottom">
							<div class="p1">辽宁首家“工业互联网+安全可控”先进制造业数字服务产业平台启动 </div>
							<div class="p2">新华财经 2023年09月26日 14:13 </div>
							<div class="p3">9月25日，由软通动力信息技术（集团）股份有限公司主办的“工业互联网+安全可控先进制造业数字服务产业峰会”在辽宁沈阳顺利举办。省市区各级政府、科研院所领导、技术…… </div>
						</div>
					</a>
				</div>
				<!-- <div class="button">
					<div>READ MORE</div>
				</div> -->
			</div>
		</div>
		<div class="cases">
			<div class="title">
				<p class="news">Cases</p>
				<p class="new">公司案例</p>
			</div>
			<div class="image">
				<img src="../../assets/images/充换电服务解决方案图.png" alt="">
			</div>
		</div>
		<div class="bottomBox">
			<div class="boxTop">
				<div class="leftItem1">
					<div class="itemTop">
						<span class="span1">联系我们</span><span class="span2">Contact us</span>
					</div>
					<p class="p1" style="margin-top: 40px;">客服电话：024-66189888</p>
					<p class="p1">公司邮箱：LNZX@isoftstone.com</p>
					<p class="p1" style="height: 20px;">公司地址：辽宁省沈阳市沈河区青年</p>
					<p class="p1" style="margin-left: 70px;">大街268-1号2101</p>
				</div>
				<div class="leftItem2">
					<div class="itemTop">
						<span class="span1">快捷导航</span><span class="span2">Quick Navigation</span>
					</div>
					<div class="itemBottom">
						<div class="itemBottomLeft">
							<p @click="getHomePages">首页</p>
							<p @click="getAbouts">关于我们</p>
							<p @click="getContactUs">联系我们</p>
						</div>
						<div class="itemBottomRight">
							<p @click="getGreenEnergy">绿色能源板块</p>
							<p @click="getIoTSectors">工业物联网板块</p>
						</div>
					</div>
				</div>
				<div class="leftItem3">
					<div class="itemTop">
						<span class="span1">关注我们</span><span class="span2">Follow us</span>
					</div>
					<div class="right">
						<div class="rightItem" style="margin-right: 15px;">
							<img src="../../assets/images/ios下载码.png" alt="">
							<p>扫描下载通通APP（IOS）</p>
						</div>
						<div class="rightItem">
							<img src="../../assets/images/android下载码.png" alt="">
							<p>扫描下载通通APP（Android)</p>
						</div>
					</div>
				</div>
			</div>
			<div class="boxBottom">
				<div class="lefts">
					<div class="leftC">c</div>
					<p>辽宁软通智新科技有限公司版权所有</p>
				</div>
        <a class="filingNumber" style="margin: 0 50px;"  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2023010593号-2</a>
        <div class="lefts">
					<img src="../../assets/images/备案图标.png" alt="">
					<p style="margin: 3px 0 0 10px;">辽公网安备21010302666676号</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {

			}
		},
		mounted() {
			this.scrollToTop();
		},
		methods: {
			scrollToTop() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			},
			clickPageChange() {
				this.$emit('dataFromChild', '' )
			},
			getHomePages() {
				this.$emit('jumpToPage', '1' )
			},
			getAbouts() {
				this.$emit('jumpToPage', '3' )
			},
			getContactUs() {
				this.$emit('jumpToPage', '4' )
			},
			getGreenEnergy() {
				this.$emit('jumpToPage', '2-1' )
			},	
			getIoTSectors() {
				this.$emit('jumpToPage', '2-2' )
			}
		}
	}
</script>

<style lang="less" scoped>
.homePage {
	height: 100%;
	.picture {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
		}
		.picturer {
			position: absolute;
			left:50%;
			top:45%;
			transform: translate(-50%, -50%);
			.p1 {
				width: 1080px;
				height: 66px;
				font-family: SourceHanSansCN-Bold;
				font-size: 66px;
				color: #FFFFFF;
				letter-spacing: 0;
				font-weight: 700;
				margin-bottom: 50px;
			}
			.p2 {
				// width: 440px;
				height: 40px;
				font-family: SourceHanSansCN-Normal;
				font-size: 40px;
				color: #FFFFFF;
				letter-spacing: 0;
				line-height: 40px;
				font-weight: 400;
			}
			.p3 {
				display: flex;
				align-items: center;
				// height: 20px;
				font-family: SourceHanSansCN-Bold;
				font-size: 20px;
				color: #FFFFFF;
				letter-spacing: 0;
				font-weight: 700;
				margin-top: 100px;
				cursor: pointer;
				div {
					width: 54px;
					height: 54px;
					border: 1px solid #fff;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 30px;
				}
			}
		}
	}
	.newsTop {
		display: flex;
		justify-content: center;
		// margin-top: -90px;
		z-index: 999;
		.newsTopItem {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 320px;
			background: #fff !important;
			box-shadow: 0 5px 0 0 #f7f7f7;
			img {
				padding: 65px 0;
				width: 50px;
				height: 50px;
				margin-right: 15px;
			}
			.newsTopItemRight {
				.device {
					width: 144px;
					height: 24px;
					font-family: PingFangSC-Semibold;
					font-size: 24px;
					color: #222222;
					letter-spacing: 0;
					line-height: 24px;
					font-weight: 600;
				}
				.service {
					margin-top: 16px;
					width: 192px;
					height: 16px;
					font-family: PingFangSC-Regular;
					font-size: 16px;
					color: #666666;
					letter-spacing: 0;
					line-height: 16px;
					font-weight: 400;
				}
			}
		}
	}
	.news {
		width: 100%;
		height: 960px;
		
		.newsBottom {
			display: flex;
			align-items: center;
			flex-direction: column;
			.title {
				margin: 100px 0;
				.news {
					width: 189px;
					height: 72px;
					font-family: Arial-BoldMT;
					font-size: 72px;
					color: #EEEEEE;
					letter-spacing: 0;
					line-height: 72px;
					font-weight: 700;
				}
				.new {
					width: 144px;
					height: 36px;
					font-family: SourceHanSansCN-Medium;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					font-weight: 500;
					margin-left: 25px;
					margin-top: -30px;
				}
			}
			.pic {
				display: flex;
				.picItem:hover {
					width: 400px;
					height: 500px;
					background: #fff;
					border-radius: 10px;
					cursor: pointer;
					box-shadow: 0px 0px 30px 0px
					rgba(204,204,204,0.4);
				}
				.picItem {
					width: 400px;
					height: 500px;
					background: #F7F7F7;
					border-radius: 10px;
					cursor: pointer;
					.picItemBottom {
						display: flex;
						flex-direction: column;
						margin-left: 30px;
						.p1 {
							width: 340px;
							height: 48px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 24px;
							font-weight: 600;
							margin-top: 30px;
						}
						.p2 {
							margin: 20px 0 30px 0;
							width: 266px;
							height: 16px;
							font-family: PingFangSC-Regular;
							font-size: 16px;
							color: #999999;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 400;
						}
						.p3 {
							width: 340px;
							height: 66px;
							font-family: PingFangSC-Regular;
							font-size: 14px;
							color: #666666;
							letter-spacing: 0;
							text-align: justify;
							line-height: 22px;
							font-weight: 400;
						}
					}
				}
			}
			.button {
				width: 140px;
				height: 50px;
				border-radius: 8px;
				border: 1px solid #008BD1;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 100px;
				div {
					height: 14px;
					font-family: PingFangSC-Semibold;
					font-size: 14px;
					color: #008BD1;
					letter-spacing: 0;
					text-align: justify;
					line-height: 14px;
					font-weight: 600;
				}
			}
		}
	}
	.cases {
		width: 100%;
		height: 1120px;
		background: yellow;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		.title {
			margin: 100px 0;
			.news {
				width: 189px;
				height: 72px;
				font-family: Arial-BoldMT;
				font-size: 72px;
				color: #666;
				letter-spacing: 0;
				line-height: 72px;
				font-weight: 700;
			}
			.new {
				width: 144px;
				height: 36px;
				font-family: SourceHanSansCN-Medium;
				font-size: 36px;
				color: #fff;
				letter-spacing: 0;
				font-weight: 500;
				margin-left: 25px;
				margin-top: -30px;
			}
		}
		.image {
			border-radius: 10px;
			overflow: hidden;
			width: 1280px;
			height: 720px;
		}
		background: #010101;
	}
	.bottomBox {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #f7f7f7;
		.boxTop {
			width: 66.6%;
			height: 440px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #EEE;
			.leftItem1 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					// height: 23px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.p1 {
					width: 234px;
					height: 40px;
					font-family: PingFangSC-Semibold;
					font-size: 14px;
					color: #999999;
					letter-spacing: 0;
					line-height: 20px;
					font-weight: 600;
				}
			}
			.leftItem2 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					// height: 23px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.itemBottom {
					width: 230px;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					p {
						cursor: pointer;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 14px;
						font-weight: 600;
					}
				}
			}
			.leftItem3 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					// height: 23px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.right {
					display: flex;
					margin-top: 50px;
					.rightItem {
						width: 210px;
						height: 240px;
						background: #fff;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-radius: 10px;
						p {
							width: 200px;
							height: 14px;
							font-family: PingFangSC-Regular;
							font-size: 14px;
							color: #222222;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 400;
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					}
				}
			}
			
		}
		.boxBottom {
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			.lefts {
				display: flex;
				.leftC {
					width: 11px;
					height: 11px;
					border-radius: 50%;
					border: 1px solid #333;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					color: #333333;
					letter-spacing: 0;
				}
			}
			p { 
				height: 14px;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
			.filingNumber {
        text-decoration: none;
        font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
      }
		}
	}
}
@media (max-width:1500px) {
  .homePage {
		height: 100%;
		.picture {
			width: 100%;
			position: relative;
			img {
				width: 100%;
				height: 100%;
			}
			.picturer {
				position: absolute;
				left:50%;
				top:50%;
				transform: translate(-50%, -50%);
				.p1 {
					// width: 396px;
					height: 66px;
					font-family: SourceHanSansCN-Bold;
					font-size: 60px;
					color: #FFFFFF;
					letter-spacing: 0;
					font-weight: 700;
					margin-bottom: 30px;
				}
				.p2 {
					// width: 440px;
					height: 40px;
					font-family: SourceHanSansCN-Normal;
					font-size: 35px;
					color: #FFFFFF;
					letter-spacing: 0;
					line-height: 40px;
					font-weight: 400;
				}
			}
		}
		.newsTop {
			display: flex;
			justify-content: center;
			.newsTopItem {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 260px;
				background: #fff !important;
				box-shadow: 0 5px 0 0 #f7f7f7;
				img {
					padding: 65px 0;
					width: 45px;
					height: 45px;
					margin-right: 15px;
				}
				.newsTopItemRight {
					.device {
						width: 144px;
						height: 24px;
						font-family: PingFangSC-Semibold;
						font-size: 20px;
						color: #222222;
						letter-spacing: 0;
						line-height: 24px;
						font-weight: 600;
					}
					.service {
						margin-top: 16px;
						width: 192px;
						height: 16px;
						font-family: PingFangSC-Regular;
						font-size: 14px;
						color: #666666;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 400;
					}
				}
			}
		}
		.news {
			width: 100%;
			height: 860px;
			
			.newsBottom {
				display: flex;
				align-items: center;
				flex-direction: column;
				.title {
					margin: 100px 0;
					.news {
						width: 189px;
						height: 72px;
						font-family: Arial-BoldMT;
						font-size: 72px;
						color: #EEEEEE;
						letter-spacing: 0;
						line-height: 72px;
						font-weight: 700;
					}
					.new {
						width: 144px;
						height: 36px;
						font-family: SourceHanSansCN-Medium;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						font-weight: 500;
						margin-left: 25px;
						margin-top: -30px;
					}
				}
				.pic {
					display: flex;
					.picItem {
						width: 320px;
						height: 420px;
						background: #F7F7F7;
						border-radius: 10px;
						.picItemBottomimg {
								width: 320px;
								height: 188px;
								border-radius: 10px;
								img {
									width: 100%;
									height: 100%;
								}
							}
						.picItemBottom {
							display: flex;
							flex-direction: column;
							margin-left: 30px;
							.p1 {
								width: 270px;
								height: 70px;
								font-family: PingFangSC-Semibold;
								font-size: 16px;
								color: #222222;
								letter-spacing: 0;
								line-height: 24px;
								font-weight: 600;
								margin-top: 15px;
							}
							.p2 {
								margin: 10px 0 10px 0;
								width: 266px;
								height: 16px;
								font-family: PingFangSC-Regular;
								font-size: 14px;
								color: #999999;
								letter-spacing: 0;
								line-height: 16px;
								font-weight: 400;
							}
							.p3 {
								width: 270px;
								height: 66px;
								font-family: PingFangSC-Regular;
								font-size: 12px;
								color: #666666;
								letter-spacing: 0;
								text-align: justify;
								line-height: 22px;
								font-weight: 400;
							}
						}
					}
					.picItem:hover {
						width: 320px;
						height: 420px;
						background: #fff;
						box-shadow: 0px 0px 30px 0px
						rgba(204,204,204,0.4);
					}
				}
				.button {
					width: 140px;
					height: 50px;
					border-radius: 8px;
					border: 1px solid #008BD1;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 100px;
					div {
						height: 14px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #008BD1;
						letter-spacing: 0;
						text-align: justify;
						line-height: 14px;
						font-weight: 600;
					}
				}
			}
		}
		.cases {
			width: 100%;
			height: 1020px;
			background: yellow;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				margin: 100px 0;
				.news {
					width: 189px;
					height: 72px;
					font-family: Arial-BoldMT;
					font-size: 72px;
					color: #666;
					letter-spacing: 0;
					line-height: 72px;
					font-weight: 700;
				}
				.new {
					width: 144px;
					height: 36px;
					font-family: SourceHanSansCN-Medium;
					font-size: 36px;
					color: #fff;
					letter-spacing: 0;
					font-weight: 500;
					margin-left: 25px;
					margin-top: -30px;
				}
			}
			.image {
				border-radius: 10px;
				overflow: hidden;
				width: 1080px;
				height: 600px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			background: #010101;
		}
		.bottomBox {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #f7f7f7;
			.boxTop {
				width: 76.6%;
				height: 440px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #EEE;
				.leftItem1 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						// height: 23px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.p1 {
						width: 234px;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 20px;
						font-weight: 600;
					}
				}
				.leftItem2 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						// height: 23px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.itemBottom {
						width: 230px;
						margin-top: 40px;
						display: flex;
						justify-content: space-between;
						p {
							cursor: pointer;
							height: 40px;
							font-family: PingFangSC-Semibold;
							font-size: 14px;
							color: #999999;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 600;
						}
					}
				}
				.leftItem3 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						// height: 23px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.right {
						display: flex;
						margin-top: 50px;
						.rightItem {
							width: 210px;
							height: 240px;
							background: #fff;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							border-radius: 10px;
							p {
								width: 200px;
								height: 14px;
								font-family: PingFangSC-Regular;
								font-size: 14px;
								color: #222222;
								letter-spacing: 0;
								line-height: 14px;
								font-weight: 400;
								display: flex;
								flex-direction: column;
								align-items: center;
							}
						}
					}
				}
				
			}
			.boxBottom {
				width: 100%;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				.lefts {
					display: flex;
					.leftC {
						width: 11px;
						height: 11px;
						border-radius: 50%;
						border: 1px solid #333;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #333333;
						letter-spacing: 0;
					}
				}
				p { 
					height: 14px;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
				.filingNumber {
					text-decoration: none;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
			}
		}
	}
}
</style>