<template>
    <div class="about">
      <header class="header">
        <img src="../assets/通通logo1.png" alt="">
        <div class="flag"></div>
        <p>用户注册</p>
      </header>
      <div class="content">
        <div class="contentBox">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="登录账号" prop="region">
              <el-input v-model="ruleForm.region" placeholder="建议使用企业邮箱"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="date1">
              <el-input type="password" placeholder="请输入密码" v-model="ruleForm.date1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="date2">
              <el-input type="password" placeholder="确认密码" v-model="ruleForm.date2" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="resource">
              <el-input type="password" placeholder="请输入手机号码" v-model="ruleForm.resource" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="" prop="type">
              <div style="display: flex;">
                <el-input style="margin-right: 20px;" placeholder="请输入验证码" type="password" v-model="ruleForm.type"></el-input><el-button type="primary">发送验证码</el-button></div>
            </el-form-item>
						<el-form-item label="支付密码" prop="desc">
              <el-input type="password" placeholder="请输入支付密码" v-model="ruleForm.desc" autocomplete="off"></el-input>
            </el-form-item>
						<el-form-item label="确认支付密码" prop="delivery">
              <el-input type="password" placeholder="请确认支付密码" v-model="ruleForm.delivery" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="checkbox">
                <el-checkbox></el-checkbox>
                <p class="read">我已阅读并同意</p>
                <p class="policy">《隐私政策》《服务协议》</p>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="buttons">
                <el-button type="primary">注册</el-button>
                <el-button @click="jumpGo">取消</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'aboutPage',
    props: {
      
    },
    data() {
      return {
        ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: '',
          type: [],
          resource: '',
          desc: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          region: [
            { required: true, message: '请输入登录账号', trigger: 'blur' }
          ],
          date1: [
            { type: 'date', required: true, message: '请输入密码', trigger: 'blur' }
          ],
          date2: [
            { type: 'date', required: true, message: '请确认密码', trigger: 'blur' }
          ],
          // type: [
          //   { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
          // ],
          resource: [
            { required: true, message: '请输入手机号码', trigger: 'blur' }
          ],
          desc: [
            { required: true, message: '请输入支付密码', trigger: 'blur' }
          ],
          delivery: [
            { required: true, message: '请确认支付密码', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      jumpGo() {
        this.$router.push({
          path: '/login'
        })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  /deep/.el-button {
    width: 40%;
    // height: 50px;
  }
  .about {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .header {
      width: 100%;
      height: 100px;
      padding: 0 190px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      img {
        width: 162px;
        height: 52px;
      }
      .flag {
        width: 1px;
        height: 20px;
        background: #CCCCCC;
        margin: 0 10px;
      }
      p {
        height: 24px;
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        text-align: right;
        line-height: 20px;
        font-weight: 600;
      }
    }
    .content {
      width: 100%;
      background: #F5F6FA;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      .contentBox {
        width: 1300px;
        height: 600px;
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 50px 300px;
        .checkbox {
          display: flex;
          margin-top: 10px;
          .read {
            margin: 12px 0 0 7px;
            width: 112px;
            height: 16px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
          }
          .policy {
            cursor: pointer; 
            margin-top: 12px;
            width: 192px;
            height: 16px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #2C57FF;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
          }
        }
        .buttons {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  
  </style>
  