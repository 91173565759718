import { render, staticRenderFns } from "./aboutU.vue?vue&type=template&id=cc2d6768&scoped=true"
import script from "./aboutU.vue?vue&type=script&lang=js"
export * from "./aboutU.vue?vue&type=script&lang=js"
import style0 from "./aboutU.vue?vue&type=style&index=0&id=cc2d6768&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc2d6768",
  null
  
)

export default component.exports