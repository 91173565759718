<template>
	<div class="homePage">
		<div class="picture">
			<img src="../../assets/images/banner5.png" alt="">
			<div class="picturer">
				<p class="p1">您的需求是我们合作的开始</p>
				<p class="p2">我们时刻期待与您的沟通</p>
			</div>
		</div>
		<div class="pic1">
			<img class="imgs" src="../../assets/images/联系方式背景.png" alt="">
			<div class="picBox">
				<div class="picBoxTop">
					<p class="p1">联系方式</p>
					<p class="p2">Contact Information</p>
				</div>
				<div class="picBoxBottom">
					<div class="picTop">
						<div class="picTopItem">
							<img src="../../assets/images/电话.png" alt="">
							<div class="picTopItemRight">
								<p class="p1">电话 </p>
								<p class="p2">024-66189888 </p>
							</div>
						</div>
						<div class="picTopItem">
							<img src="../../assets/images/邮箱.png" alt="">
							<div class="picTopItemRight">
								<p class="p1">邮箱 </p>
								<p class="p2">LNZX@isoftstone.com </p>
							</div>
						</div>
						<div class="picTopItem">
							<img src="../../assets/images/地址.png" alt="">
							<div class="picTopItemRight">
								<p class="p1">地址 </p>
								<p class="p2">辽宁省沈阳市沈河区青年大街268-1号2101</p>
							</div>
						</div>
					</div>
					<img  src="../../assets/images/小地图.png" alt="">
				</div>
			</div>
		</div>
		<div class="bottomBox">
			<div class="boxTop">
				<div class="leftItem1">
					<div class="itemTop">
						<span class="span1">联系我们</span><span class="span2">Contact us</span>
					</div>
					<p class="p1" style="margin-top: 40px;">客服电话：024-66189888</p>
					<p class="p1">公司邮箱：LNZX@isoftstone.com</p>
					<p class="p1" style="height: 20px;">公司地址：辽宁省沈阳市沈河区青年</p>
					<p class="p1" style="margin-left: 70px;">大街268-1号2101</p>
				</div>
				<div class="leftItem2">
					<div class="itemTop">
						<span class="span1">快捷导航</span><span class="span2">Quick Navigation</span>
					</div>
					<div class="itemBottom">
						<div class="itemBottomLeft">
							<p @click="getHomePages">首页</p>
							<p @click="getAbouts">关于我们</p>
							<p @click="getContactUs">联系我们</p>
						</div>
						<div class="itemBottomRight">
							<p @click="getGreenEnergy">绿色能源板块</p>
							<p @click="getIoTSectors">工业物联网板块</p>
						</div>
					</div>
				</div>
				<div class="leftItem3">
					<div class="itemTop">
						<span class="span1">关注我们</span><span class="span2">Follow us</span>
					</div>
					<div class="right">
						<div class="rightItem" style="margin-right: 20px;">
							<img src="../../assets/images/ios下载码.png" alt="">
							<p>扫描下载通通APP（IOS）</p>
						</div>
						<div class="rightItem">
							<img src="../../assets/images/android下载码.png" alt="">
							<p>扫描下载通通APP（Android)</p>
						</div>
					</div>
				</div>
			</div>
			<div class="boxBottom">
				<div class="lefts">
					<div class="leftC">c</div>
					<p>辽宁软通智新科技有限公司版权所有</p>
				</div>
        <a class="filingNumber" style="margin: 0 50px;"  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2023010593号-2</a>
        <div class="lefts">
					<img src="../../assets/images/备案图标.png" alt="">
					<p style="margin: 3px 0 0 10px;">辽公网安备21010302666676号</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	mounted() {
		this.scrollToTop();
	},
	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		getHomePages() {
			this.$emit('jumpToPage', '1' )
		},
		getAbouts() {
			this.$emit('jumpToPage', '3' )
		},
		getContactUs() {
			this.$emit('jumpToPage', '4' )
		},
		getGreenEnergy() {
			this.$emit('jumpToPage', '2-1' )
		},	
		getIoTSectors() {
			this.$emit('jumpToPage', '2-2' )
		}
	}
}
</script>

<style lang="less" scoped>
.homePage {
	height: 100%;
	.picture {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
		}
		.picturer {
			position: absolute;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%);
			.p1 {
				width: 1096px;
				height: 66px;
				font-family: SourceHanSansCN-Bold;
				font-size: 66px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				font-weight: 700;
				margin-bottom: 30px;
			}
			.p2 {
				// width: 440px;
				height: 40px;
				font-family: SourceHanSansCN-Normal;
				font-size: 40px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				line-height: 40px;
				font-weight: 400;
				margin-left: -20px;
			}
		}
	}
	.newsBottom {
		width: 100%;
		height: 900px;
		display: flex;
		align-items: center;
		flex-direction: column;
		background: #fafafa;
		.title {
			margin: 55px 0;
			.news {
				width: 144px;
				height: 50px;
				font-family: PingFangSC-Semibold;
				font-size: 36px;
				color: #222222;
				letter-spacing: 0;
				// text-align: center;
				font-weight: 600;
			}
			.new {
				// width: 200px;
				height: 24px;
				font-family: ArialMT;
				font-size: 24px;
				color: #666666;
				margin-left: -40px;
				letter-spacing: 0;
				// text-align: center;
				line-height: 24px;
				font-weight: 400;
			}
		}
		.images {
			display: flex;
			.characters {
				width: 500px;
				height: 320px;
				font-family: PingFangSC-Regular;
				font-size: 18px;
				color: #222222;
				letter-spacing: 0;
				line-height: 32px;
				font-weight: 400;
				margin: 50px 50px 0 0;
			}
		}
	}
	.pic1 {
		width: 100%;
		// height: 1150px;
		position: relative;
		background: #fff;
		font-size: 0px;
		.imgs {
			width: 100%;
			height: 100%;
		}
		.picBox {
			width: 66.6%;
			// height: 1000px;
			position: absolute;
			background: #fff;
			padding: 20px 30px;
			top: 0;
			left: 16.6%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.picBoxTop {
				.p1 {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					text-align: center;
					font-weight: 600;
					margin: 36px 0 5px 0;
				}
				.p2 {
					margin: 0 0 20px -40px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					letter-spacing: 0;
					text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.picBoxBottom {
				display: flex;
				flex-direction: column;
				width: 100%;
				.picTop {
					.picTopItem {
						display: flex;
						margin: 60px 0;
						.picTopItemRight {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							margin-left: 24px;
							.p1 {
								font-family: PingFangSC-Regular;
								font-size: 14px;
								color: #666666;
								letter-spacing: 0;
								// text-align: center;
								line-height: 14px;
								font-weight: 400;
							}
							.p2 {
								font-family: PingFangSC-Semibold;
								font-size: 24px;
								color: #333333;
								letter-spacing: 0;
								line-height: 24px;
								font-weight: 600;
							}
						}
					}
				}
				img {
					margin-bottom: 10px;
				}
			}
		}
	}
	.bottomBox {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #f7f7f7;
		.boxTop {
			width: 66.6%;
			height: 440px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #EEE;
			.leftItem1 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.p1 {
					width: 234px;
					height: 40px;
					font-family: PingFangSC-Semibold;
					font-size: 14px;
					color: #999999;
					letter-spacing: 0;
					line-height: 20px;
					font-weight: 600;
				}
			}
			.leftItem2 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.itemBottom {
					width: 230px;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					p {
						cursor: pointer;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 14px;
						font-weight: 600;
					}
				}
			}
			.leftItem3 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.right {
					display: flex;
					margin-top: 50px;
					.rightItem {
						width: 200px;
						height: 240px;
						background: #fff;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-radius: 10px;
						p {
							width: 200px;
							height: 14px;
							font-family: PingFangSC-Regular;
							font-size: 14px;
							color: #222222;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 400;
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					}
				}
			}
			
		}
		.boxBottom {
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			.lefts {
				display: flex;
				.leftC {
					width: 11px;
					height: 11px;
					border-radius: 50%;
					border: 1px solid #333;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					color: #333333;
					letter-spacing: 0;
				}
			}
			p { 
				height: 14px;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
			.filingNumber {
				text-decoration: none;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
		}
	}
}
@media (max-width:1500px) { 
	.homePage {
		height: 100%;
		.picture {
			width: 100%;
			position: relative;
			img {
				width: 100%;
				height: 100%;
			}
			.picturer {
				position: absolute;
				left:50%;
				top:50%;
				transform: translate(-50%, -50%);
				.p1 {
					width: 1096px;
					height: 66px;
					font-family: SourceHanSansCN-Bold;
					font-size: 60px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					font-weight: 700;
					margin-bottom: 30px;
				}
				.p2 {
					// width: 440px;
					height: 40px;
					font-family: SourceHanSansCN-Normal;
					font-size: 35px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					line-height: 40px;
					font-weight: 400;
					margin-left: -20px;
				}
			}
		}
		.newsBottom {
			width: 100%;
			height: 900px;
			display: flex;
			align-items: center;
			flex-direction: column;
			background: #fafafa;
			.title {
				margin: 55px 0;
				.news {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					// text-align: center;
					font-weight: 600;
				}
				.new {
					// width: 200px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					margin-left: -40px;
					letter-spacing: 0;
					// text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.images {
				display: flex;
				.characters {
					width: 500px;
					height: 320px;
					font-family: PingFangSC-Regular;
					font-size: 18px;
					color: #222222;
					letter-spacing: 0;
					line-height: 32px;
					font-weight: 400;
					margin: 50px 50px 0 0;
				}
			}
		}
		.pic1 {
			width: 100%;
			height: 1050px;
			position: relative;
			background: #fff;
			.imgs {
				width: 100%;
				height: 100%;
			}
			.picBox {
				width: 76.6%;
				// height: 900px;
				padding: 20px;
				position: absolute;
				background: #fff;
				top: 2%;
				left: 11.6%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.picBoxTop {
					.p1 {
						width: 144px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						text-align: center;
						font-weight: 600;
						margin: 66px 0 5px 0;
					}
					.p2 {
						margin: 0 0 60px -40px;
						height: 24px;
						font-family: ArialMT;
						font-size: 24px;
						color: #666666;
						letter-spacing: 0;
						text-align: center;
						line-height: 24px;
						font-weight: 400;
					}
				}
				.picBoxBottom {
					display: flex;
					flex-direction: column;
					width: 100%;
					.picTop {
						.picTopItem {
							display: flex;
							margin: 60px 0;
							img {
								width: 45px;
								height: 45px;
							}
							.picTopItemRight {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								margin-left: 24px;
								.p1 {
									font-family: PingFangSC-Regular;
									font-size: 14px;
									color: #666666;
									letter-spacing: 0;
									// text-align: center;
									line-height: 14px;
									font-weight: 400;
								}
								.p2 {
									font-family: PingFangSC-Semibold;
									font-size: 22px;
									color: #333333;
									letter-spacing: 0;
									line-height: 24px;
									font-weight: 600;
								}
							}
						}
					}
					img {
					margin-bottom: 1px;
				}
				}
			}
		}
		.bottomBox {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #f7f7f7;
			.boxTop {
				width: 76.6%;
				height: 440px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #EEE;
				.leftItem1 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.p1 {
						width: 234px;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 20px;
						font-weight: 600;
					}
				}
				.leftItem2 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.itemBottom {
						width: 230px;
						margin-top: 40px;
						display: flex;
						justify-content: space-between;
						p {
							cursor: pointer;
							height: 40px;
							font-family: PingFangSC-Semibold;
							font-size: 14px;
							color: #999999;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 600;
						}
					}
				}
				.leftItem3 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.right {
						display: flex;
						margin-top: 50px;
						.rightItem {
							width: 200px;
							height: 240px;
							background: #fff;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							border-radius: 10px;
							p {
								width: 200px;
								height: 14px;
								font-family: PingFangSC-Regular;
								font-size: 14px;
								color: #222222;
								letter-spacing: 0;
								line-height: 14px;
								font-weight: 400;
								display: flex;
								flex-direction: column;
								align-items: center;
							}
						}
					}
				}
				
			}
			.boxBottom {
				width: 100%;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				.lefts {
					display: flex;
					.leftC {
						width: 11px;
						height: 11px;
						border-radius: 50%;
						border: 1px solid #333;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #333333;
						letter-spacing: 0;
					}
				}
				p { 
					height: 14px;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
				.filingNumber {
					text-decoration: none;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
			}
		}
	}
}
</style>