import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(ElementUI);

window.addEventListener('resize', function() {
  document.documentElement.style.fontSize = document.documentElement.clientWidth / 1920 * 100 + 'px';
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
