import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import LoginView from '../views/LoginView.vue'
import Register from '../views/Register.vue'
import Account from '../views/Account.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'home'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
// router.beforeEach((to, from, next) => {
//   const isMobile = window.innerWidth < 900 // 设置移动设备的屏幕分辨率阈值
//   if (to.matched.some(record => record.meta.requiresMobile) && !isMobile) {
//     next('/home') // 如果路由需要移动设备而当前是桌面设备，则跳转到桌面路由
//   } else if (to.matched.some(record => record.meta.requiresDesktop) && isMobile) {
//     next('/about') // 如果路由需要桌面设备而当前是移动设备，则跳转到移动路由
//   } else {
//     next() // 否则继续正常导航
//   }
// })
