<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/home">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: null, 
      flag: null
    }
  },
  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
    );
    // console.log(this.flag, 'this.flag22222222')
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if (val < 900 || this.flag) {
          console.log(val + '屏幕宽度不能小于1024')
          // this.$router.replace({
          //   path: '/about'
          // })
        } else {
          console.log(val + '屏幕宽度属于正常状态')
          // this.$router.replace({
          //   path: '/home'
          // })
        }
      }
    },
  }
}
</script>
<style>
html, p, 
body {
  margin: 0;
  padding: 0;
}
html {
  font-size: 100px;
}
</style>
