<template>
	<div class="homePage">
		<div class="picture">
			<img src="../../assets/images/banner4.png" alt="">
			<div class="picturer">
				<p class="p1">拥抱绿色能源</p>
				<p class="p2">打造低碳经济发展新模式</p>
			</div>
		</div>
		<div class="newsBottom">
			<img class="imgs" src="../../assets/images/绿色能源背景.png" alt="">
			<div class="newsBottomBox">
				<div class="title">
					<p class="news">绿色能源</p>
					<p class="new">Green Energy</p>
				</div>
				<div class="images">
					<div class="characters" style="text-indent:2em">
						坚持以习近平新时代中国特色社会主义思想为指导，加快生态文明建设、推动经济转型，关系人民福祉，关乎民族未来，事关“两个一百年”奋斗目标和中华民族伟大复兴的实现。辽宁软通智新怀着强烈的责任感与使命感成为“碳达峰、碳中和”战略落实的推动者和贡献者，“碳”寻未来新发展，敢为数字科技与能源技术融合创新之人先，担当能源企业数字化转型的护航者。辽宁软通智新始终坚持数字技术对绿色经济的赋能，专注碳达峰碳中和技术创新和研发，以科技创新推动绿色发展，助力客户实现低碳战略下的数字化转型，贯彻落实“双碳”目标的实现。
					</div>
					<img src="../../assets/images/绿色能源图.png" alt="">
				</div>
			</div>
		</div>
		<div class="pic1">
			<div class="pic1Box">
				<div class="pic1BoxTop">
					<div class="pic1BoxTopTitle">
						<span class="title1">01</span><span  class="title2">虚拟电厂运营管理解决方案</span>
					</div>
					<div class="pic1BoxTopTitle2">
						<p class="title3">虚拟电厂（Virtual Power Plant，简称VPP）指的是通过整合多个分布式能源资源（如风力发电、太阳能发电、储能系统等）并进行智能管理和运营的电力系统。虚拟电厂通过互联网、物联网和智能电网技术等手段将分散的能源资源、负荷需求和储能系统进行集成，形成一个虚拟的、统一管理的电力系统。</p>
						<p class="title5"><span>1. 多源能源整合：</span>将分散的可再生能源、储能系统等能源资源通过互联网等技术进行连接整合，实现能源的灵活调度和优化控制。</p>
						<p class="title5"><span>2. 智能电网管理：</span>利用物联网、人工智能和大数据等技术，实现对虚拟电厂的集中监控、调度和运营管理，提高系统的可靠性、灵活性和效率。</p>
						<p class="title5"><span>3. 能源市场参与：</span>虚拟电厂可以参与能源市场，通过电力销售、电力交易等方式实现能源的优化利用和经济效益最大化。</p>
						<p class="title5"><span>4. 网络协同运营：</span>虚拟电厂能够与传统电力系统、用户侧负荷、电网企业等进行网络协同运营，实现能源的有序调度和资源的共享利用。</p>
					</div>
				</div>
				<img src="../../assets/images/虚拟电厂运营管理解决方案图.png" alt="">
			</div>
		</div>
		<div class="pic2">
			<img class="imgs" src="../../assets/images/背景图2.png" alt="">
			<div class="pic2Box">
				<h1>虚拟电厂-N个用能聚合场景板块-三大目标”的运营模式应时而生</h1>
				<h3>集智能管理调度、电力本地高频消纳、双碳场景支撑能力与一身的建设绿色双碳数字能源综合体系</h3>
				<img src="../../assets/images/虚拟电厂-N个用能聚合场景板块-三大目标图.png" alt="">
			</div>
		</div>
		<div class="pic3">
			<div class="pic1Box">
				<div class="pic1BoxTopTitle">
					<span class="title1" style="margin-right: 10px;">02</span>
					<span  class="title2">充换电服务解决方案</span>
				</div>
				<p class="title3">通通充换电服务平台，是基于辽宁软通智新物联网技术和综合能源服务平台构建的一体化运营管理平台，统一监控后台通过对充电桩、充电机及配电系统数据的采集存储、处理分析，提供图形化人机监控界面，实现智能充电、保障供电系统安全、提升监控人员工作效率，从而确保充电设施安全、可靠、经济运行，满足充电桩服务商、用户及三方监管机构的需求。
				</p>
				<!-- <img src="../../assets/images/充换电服务解决方案图.png" alt=""> -->
				<video controls>
					<source src="../../assets/video.mp4" type="video/mp4">
				</video>
			</div>
		</div>
		<div class="pic4" style="background: #F7F7F7;">
			<div class="pic1Box">
				<div class="pic1BoxTopTitle">
					<span class="title1" style="margin-right: 10px;">03</span>
					<span  class="title2">风光储充一体化综合能源解决方案</span>
				</div>
				<p class="title3">风光储充一体化运营管控平台，包含风电、光伏发电系统、储能逆变系统、电动汽车充电系统、智慧照明系统、安防监控系统等。与传统的解决方案相比，将新能源光伏发电、汽车充电、电池储能、节能技术整合到一起，通过物联网平台进行互联互通，实时监控能量流动，集中监控、智能管理。
				</p>
				<p class="title3" style="margin-top: -30px;">
					风光储充一体化运营管控平台利用大云物移智等前沿技术，围绕分布式电源 “可观、可测、可控、可调”核心目标，融合电网营销业务应用、用电信息采集、调度OMS等系统数据，为用户提供丰富的应用场景，如新能源全景看板、新能源在线监测、新能源远程控制、新能源发电预测、新能源智能调度、新能源市场运营、新能源并网管理等一站式服务。以“服务发电企业”为宗旨，持续挖掘光伏、水电、风电等分布式电源调节潜能，提升新能源消纳能力，立足构建新型电力系统，服务碳达峰、碳中和目标大局，全力保障电网高质量发展。
				</p>
				<img src="../../assets/images/风光储充一体化综合能源解决方案图.png" alt="">
			</div>
		</div>
		<div class="bottomBox">
			<div class="boxTop">
				<div class="leftItem1">
					<div class="itemTop">
						<span class="span1">联系我们</span><span class="span2">Contact us</span>
					</div>
					<p class="p1" style="margin-top: 40px;">客服电话：024-66189888</p>
					<p class="p1">公司邮箱：LNZX@isoftstone.com</p>
					<p class="p1" style="height: 20px;">公司地址：辽宁省沈阳市沈河区青年</p>
					<p class="p1" style="margin-left: 70px;">大街268-1号2101</p>
				</div>
				<div class="leftItem2">
					<div class="itemTop">
						<span class="span1">快捷导航</span><span class="span2">Quick Navigation</span>
					</div>
					<div class="itemBottom">
						<div class="itemBottomLeft">
							<p @click="getHomePages">首页</p>
							<p @click="getAbouts">关于我们</p>
							<p @click="getContactUs">联系我们</p>
						</div>
						<div class="itemBottomRight">
							<p @click="getGreenEnergy">绿色能源板块</p>
							<p @click="getIoTSectors">工业物联网板块</p>
						</div>
					</div>
				</div>
				<div class="leftItem3">
					<div class="itemTop">
						<span class="span1">关注我们</span><span class="span2">Follow us</span>
					</div>
					<div class="right">
						<div class="rightItem" style="margin-right: 20px;">
							<img src="../../assets/images/ios下载码.png" alt="">
							<p>扫描下载通通APP（IOS）</p>
						</div>
						<div class="rightItem">
							<img src="../../assets/images/android下载码.png" alt="">
							<p>扫描下载通通APP（Android)</p>
						</div>
					</div>
				</div>
			</div>
			<div class="boxBottom">
				<div class="lefts">
					<div class="leftC">c</div>
					<p>辽宁软通智新科技有限公司版权所有</p>
				</div>
        <a class="filingNumber" style="margin: 0 50px;"  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2023010593号-2</a>
        <div class="lefts">
					<img src="../../assets/images/备案图标.png" alt="">
					<p style="margin: 3px 0 0 10px;">辽公网安备21010302666676号</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	mounted() {
		this.scrollToTop();
	},
	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		getHomePages() {
			this.$emit('jumpToPage', '1' )
		},
		getAbouts() {
			this.$emit('jumpToPage', '3' )
		},
		getContactUs() {
			this.$emit('jumpToPage', '4' )
		},
		getGreenEnergy() {
			this.$emit('jumpToPage', '2-1' )
		},	
		getIoTSectors() {
			this.$emit('jumpToPage', '2-2' )
		}
	}
}
</script>

<style lang="less" scoped>
.homePage {
	height: 100%;
	.picture {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
		}
		.picturer {
			position: absolute;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%);
			.p1 {
				width: 1096px;
				height: 66px;
				font-family: SourceHanSansCN-Bold;
				font-size: 66px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				font-weight: 700;
				margin-bottom: 30px;
			}
			.p2 {
				// width: 440px;
				height: 40px;
				font-family: SourceHanSansCN-Normal;
				font-size: 40px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				line-height: 40px;
				font-weight: 400;
				// margin-left: -10px;
			}
		}
	}
		
	.newsBottom {
		width: 100%;
		// height: 900px;
		position: relative;
		background: #fff;
		.imgs {
			width: 100%;
			height: 100%;
		}
		.newsBottomBox {
			width: 66.7%;
			position: absolute;
			top: 0;
			left: 16%;
			display: flex;
			align-items: center;
			flex-direction: column;
			background: #fff;
			.title {
				margin: 55px 0;
				display: flex;
				flex-direction: column;	
				align-items: center;		
				.news {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					// text-align: center;
					font-weight: 600;
				}
				.new {
					width: 170px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					letter-spacing: 0;
					// text-align: center;
					line-height: 24px;
					font-weight: 400;
					margin-left: 15px;
				}
			}
			.images {
				display: flex;
				.characters {
					width: 49%;
					// height: 320px;
					font-family: PingFangSC-Regular;
					font-size: 20px;
					color: #222222;
					letter-spacing: 0;
					line-height: 32px;
					font-weight: 400;
					margin: 50px 50px 0 30px;
				}
				img{
					width: 47%;
				}
			}
		}
	}
	.pic1 {
		width: 100%;
		// height: 1150px;
		display: flex;
		justify-content: center;
		.pic1Box {
			width: 66.6%;
			height: 100%;
			.pic1BoxTop {
				height: 380px;
				.pic1BoxTopTitle {
					margin-top: 50px;
					.title1 {
						width: 58px;
						height: 60px;
						opacity: 0.4;
						font-family: DINAlternate-Bold;
						font-size: 60px;
						color: #008BD1;
						letter-spacing: 0;
						text-align: center;
						line-height: 60px;
						font-weight: 700;
					}
					.title2 {
						width: 432px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						font-weight: 600;
					}
				}
				.pic1BoxTopTitle2 {
					margin-left: 76px;
					margin-top: 60px;
					.title3, .title4{
						width: 1200px;
						// height: 28px;
						font-family: PingFangSC-Regular;
						font-size: 20px;
						color: #333333;
						letter-spacing: 0;
						text-align: justify;
						line-height: 35px;
						font-weight: 400;
					}
					.title5 {
						width: 1200px;
						height: 28px;
						font-family: PingFangSC-Regular;
						font-size: 16px;
						color: #666666;
						margin: 3px 0;
						letter-spacing: 0;
						text-align: justify;
						line-height: 28px;
						font-weight: 400;
						span {
							color: #333333;
						}
					} 
				}
			}
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.pic2 {
		width: 100%;
		// height: 1180px;
		background: #fff;
		position: relative;
		.imgs {
			width: 100%;
			height: 100%;
			// margin-top: 100px;
		}
		.pic2Box {
			width: 66.6%;
			position: absolute;
			background: #fff;
			padding: 20px;
			top: 1%;
			left: 16.6%;
			h1 {
				width: 1229px;
				height: 50px;
				font-family: PingFangSC-Semibold;
				font-size: 36px;
				margin: 100px 0 50px 50px;
				color: #222222;
				letter-spacing: 0;
				font-weight: 600;
			}
			h3 {
				width: 1252px;
				height: 60px;
				font-family: PingFangSC-Regular;
				font-size: 20px;
				color: #333;
				letter-spacing: 0;
				text-align: justify;
				line-height: 28px;
				font-weight: 400;
				margin-left: 50px;
			}
			img {
				// margin: 50px;
				width: 100%;
			}
		}
	}
	.pic3,.pic4 {
		width: 100%;
		// height: 1120px;
		background: #fff;
		display: flex;
		justify-content: center;
		padding-top: 50px;
		.pic1Box {
			width: 66.6%;
			padding: 50px 0;
			// height: 100%;
				.pic1BoxTopTitle {
					margin-top: 30px;
					.title1 {
						width: 58px;
						height: 60px;
						opacity: 0.4;
						font-family: DINAlternate-Bold;
						font-size: 60px;
						color: #008BD1;
						letter-spacing: 0;
						text-align: center;
						line-height: 60px;
						font-weight: 700;
					}
					.title2 {
						width: 432px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						font-weight: 600;
					}
				}
				.title3 {
					margin: 30px 0 30px 50px;
					width: 1125px;
					// height: 154px;
					font-family: PingFangSC-Regular;
					font-size: 20px;
					color: #333333;
					letter-spacing: 0;
					text-align: justify;
					line-height: 28px;
					font-weight: 400;
					margin-left: 85px;
				}
				img, video {
					width: 100%;
				}
		}
	}
	.bottomBox {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #f7f7f7;
		.boxTop {
			width: 66.6%;
			height: 440px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #EEE;
			.leftItem1 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.p1 {
					width: 234px;
					height: 40px;
					font-family: PingFangSC-Semibold;
					font-size: 14px;
					color: #999999;
					letter-spacing: 0;
					line-height: 20px;
					font-weight: 600;
				}
			}
			.leftItem2 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.itemBottom {
					width: 230px;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					p {
						cursor: pointer;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 14px;
						font-weight: 600;
					}
				}
			}
			.leftItem3 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.right {
					display: flex;
					margin-top: 50px;
					.rightItem {
						width: 200px;
						height: 240px;
						background: #fff;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-radius: 10px;
						p {
							width: 200px;
							height: 14px;
							font-family: PingFangSC-Regular;
							font-size: 14px;
							color: #222222;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 400;
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					}
				}
			}
			
		}
		.boxBottom {
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			.lefts {
				display: flex;
				.leftC {
					width: 11px;
					height: 11px;
					border-radius: 50%;
					border: 1px solid #333;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					color: #333333;
					letter-spacing: 0;
				}
			}
			p { 
				height: 14px;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
			.filingNumber {
        text-decoration: none;
        font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
      }
		}
	}
}
@media (max-width:1500px) {
	.homePage {
		height: 100%;
		.picture {
			width: 100%;
			position: relative;
			img {
				width: 100%;
				height: 100%;
			}
			.picturer {
				position: absolute;
				left:50%;
				top:50%;
				transform: translate(-50%, -50%);
				.p1 {
					width: 1096px;
					height: 66px;
					font-family: SourceHanSansCN-Bold;
					font-size: 60px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					font-weight: 700;
					margin-bottom: 30px;
				}
				.p2 {
					// width: 440px;
					height: 40px;
					font-family: SourceHanSansCN-Normal;
					font-size: 35px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					line-height: 40px;
					font-weight: 400;
					// margin-left: -20px;
				}
			}
		}
			
		.newsBottom {
			width: 100%;
			height: 800px;
			position: relative;
			background: #fff;
			.imgs {
				width: 100%;
				height: 100%;
			}
			.newsBottomBox {
				width: 76.7%;
				position: absolute;
				top: 8%;
				left: 11.6%;
				display: flex;
				align-items: center;
				flex-direction: column;
				background: #fff;
				.title {
					margin: 35px 0;
					.news {
						width: 144px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						font-weight: 600;
					}
					.new {
						width: 170px;
						height: 24px;
						font-family: ArialMT;
						font-size: 24px;
						color: #666666;
						letter-spacing: 0;
						line-height: 24px;
						font-weight: 400;
						margin-left: 15px;
					}
				}
				.images {
					display: flex;
					.characters {
						width: 400px;
						height: 367px;
						font-family: PingFangSC-Regular;
						font-size: 18px;
						color: #222222;
						letter-spacing: 0;
						line-height: 30px;
						font-weight: 400;
						margin: 50px 50px 0 0;
					}
					img {
						width: 580px;
						height: 513px;
					}
				}
			}
		}
		.pic1 {
			width: 100%;
			height: 1050px;
			display: flex;
			justify-content: center;
			.pic1Box {
				width: 76.6%;
				height: 100%;
				.pic1BoxTop {
					height: 380px;
					.pic1BoxTopTitle {
						margin-top: 50px;
						.title1 {
							width: 58px;
							height: 60px;
							opacity: 0.4;
							font-family: DINAlternate-Bold;
							font-size: 50px;
							color: #008BD1;
							letter-spacing: 0;
							text-align: center;
							line-height: 60px;
							font-weight: 700;
						}
						.title2 {
							width: 432px;
							height: 50px;
							font-family: PingFangSC-Semibold;
							font-size: 30px;
							color: #222222;
							letter-spacing: 0;
							font-weight: 600;
						}
					}
					.pic1BoxTopTitle2 {
						margin-left: 62px;
						margin-top: 40px;
						.title3, .title4{
							// height: 60px;
							width: 1000px;
							font-family: PingFangSC-Semibold;
							font-size: 18px;
							color: #222;
							letter-spacing: 0;
							text-align: justify;
							line-height: 35px;
							font-weight: 400;
						}
						.title5 {
							width: 1080px;
							// height: 60px;
							font-family: PingFangSC-Regular;
							font-size: 16px;
							margin: 3px 0;
							color: #666666;
							letter-spacing: 0;
							text-align: justify;
							line-height: 28px;
							font-weight: 400;
						} 
					}
				}
				img {
					width: 1080px;
					height: 600px;
				}
			}
		}
		.pic2 {
			width: 100%;
			height: 870px;
			background: #fff;
			position: relative;
			.imgs {
				width: 100%;
				height: 100%;
			}
			.pic2Box {
				width: 76.6%;
				position: absolute;
				background: #fff;
				padding: 10px;
				top: 1%;
				left: 11.6%;
				h1 {
					width: 1229px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 30px;
					margin: 50px 0 10px 0;
					color: #222222;
					letter-spacing: 0;
					font-weight: 600;
					margin-left: 62px;
				}
				h3 {
					// width: 1252px;
					height: 60px;
					margin-left: 62px;
					font-family: PingFangSC-Regular;
					font-size: 18px;
					color: #333;
					letter-spacing: 0;
					text-align: justify;
					line-height: 28px;
					font-weight: 400;
				}
				img {
					margin: 10px 0 0 0;
					width: 100%;
					height: 585px;
				}
			}
		}
		.pic3,.pic4 {
			width: 100%;
			height: 1000px;
			background: #fff;
			display: flex;
			justify-content: center;
			.pic1Box {
				width: 76.6%;
				height: 100%;
				.pic1BoxTopTitle {
					margin-top: 30px;
					.title1 {
						width: 58px;
						height: 60px;
						opacity: 0.4;
						font-family: DINAlternate-Bold;
						font-size: 50px;
						color: #008BD1;
						letter-spacing: 0;
						text-align: center;
						line-height: 60px;
						font-weight: 700;
					}
					.title2 {
						width: 432px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 30px;
						color: #222222;
						letter-spacing: 0;
						font-weight: 600;
					}
				}
				.title3 {
					margin: 30px 0 30px 20px;
					width: 1025px;
					// height: 154px;
					font-family: PingFangSC-Regular;
					font-size: 18px;
					color: #333;
					letter-spacing: 0;
					text-align: justify;
					line-height: 28px;
					font-weight: 400;
					margin-left: 72px;
				}
				video, img {
					width: 1080px;
					height: 606px;
				}
			}
		}
		.bottomBox {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #f7f7f7;
			.boxTop {
				width: 76.6%;
				height: 440px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #EEE;
				.leftItem1 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.p1 {
						width: 234px;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 20px;
						font-weight: 600;
					}
				}
				.leftItem2 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.itemBottom {
						width: 230px;
						margin-top: 40px;
						display: flex;
						justify-content: space-between;
						p {
							cursor: pointer;
							height: 40px;
							font-family: PingFangSC-Semibold;
							font-size: 14px;
							color: #999999;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 600;
						}
					}
				}
				.leftItem3 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.right {
						display: flex;
						margin-top: 50px;
						.rightItem {
							width: 200px;
							height: 240px;
							background: #fff;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							border-radius: 10px;
							p {
								width: 200px;
								height: 14px;
								font-family: PingFangSC-Regular;
								font-size: 14px;
								color: #222222;
								letter-spacing: 0;
								line-height: 14px;
								font-weight: 400;
								display: flex;
								flex-direction: column;
								align-items: center;
							}
						}
					}
				}
				
			}
			.boxBottom {
				width: 100%;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				.lefts {
					display: flex;
					.leftC {
						width: 11px;
						height: 11px;
						border-radius: 50%;
						border: 1px solid #333;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #333333;
						letter-spacing: 0;
					}
				}
				p { 
					height: 14px;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
				.filingNumber {
					text-decoration: none;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
			}
		}
	}
}
</style>