<template>
  <div class="homePage">
    <header class="header">
      <img class="logo1" src="../assets/images/头部蒙版.png" alt="">
      <img class="logo" src="../assets/Logo.png" alt="">
      <div class="navContent">
        <ul>
          <li :class="{ active: active === 0 }"  @click="activeIndex1">首页</li>
          <li
            :class="{ active: active === 1 }"  
            @click="activeIndex2"
            @mouseover="showMenu = true" 
            @mouseleave="showMenu = false"
            style="height: 100px;"
          >
            产品与解决方案
            <div v-if="showMenu" class="dropdown" >
              <p :class="{ activeP: activeP === 1 }" @click="activeP1" style="margin: 15px 0 10px 0;">绿色能源板块</p>
              <p :class="{ activeP: activeP === 2 }" @click="activeP2">工业物联网板块</p>
            </div>
          </li>
          <li :class="{ active: active === 2 }"  @click="activeIndex3">关于我们</li>
          <li :class="{ active: active === 3 }"  @click="activeIndex4">联系我们</li>
        </ul>
      </div>
      <!-- <div class="login" @click="loginHome">登录</div> -->
    </header>
    <div style="height: 100%;margin-top: -80px;">
      <home-pages @dataFromChild="receiveDataFromChild" @jumpToPage="jumpToPage" v-if="activeIndex === '1'"/>
      <GreenEnergy @jumpToPage="jumpToPage"  v-if="activeIndex === '2-1'"/>
      <ioTSectors @jumpToPage="jumpToPage"  v-if="activeIndex === '2-2'"/>
      <AboutUs @jumpToPage="jumpToPage"  v-if="activeIndex === '3'"/>
      <ContactUs @jumpToPage="jumpToPage"  v-if="activeIndex === '4'"/>
    </div>
    
  </div>
</template>

<script>
import HomePages from './pages/home.vue';
import GreenEnergy from './pages/greenEnergy.vue';
import ioTSectors from './pages/ioTSectors.vue';
import AboutUs from './pages/aboutU.vue';
import ContactUs from './pages/contactUs.vue';
export default {
  name: 'HomePage',
  components: {
    HomePages,
    GreenEnergy,
    ioTSectors,
    AboutUs,
    ContactUs
  },
  data() {
    return {
      activeIndex: '1',
      navList: [
        '首页', '产品与解决方案', '关于我们', '联系我们'
      ],
      active: 0,
      activeP: 0,
      showMenu: false
    }
  },
  props: {
    
  },
  methods: {
    activeIndex1() {
      this.active = 0;
      this.activeIndex = '1'
    },
    activeIndex2() {
      this.active = 1;
    },
    activeIndex3() {
      this.active = 2;
      this.activeIndex = '3'
    },
    activeIndex4() {
      this.active = 3;
      this.activeIndex = '4'
    },
    activeP1() {
      this.activeP = 1;
      this.activeIndex = '2-1'
    },
    activeP2() {
      this.activeP = 2;
      this.activeIndex = '2-2'
    },
    receiveDataFromChild() {
      this.activeIndex = '3'
      this.active = 2 
    },
    jumpToPage(data) {
      this.activeIndex = data;
      if(data === '1') { 
        this.active = 0 
      } else if (data === '2-1') { 
        this.active = 1 
        this.activeP = 1
      } else if (data === '2-2') { 
        this.active = 1 
        this.activeP = 2
      } else if (data === '3') { 
        this.active = 2 
      } else if (data === '4') { 
        this.active = 3
      }
    },
    loginHome() {
      this.$router.replace({
        path: '/login'
      })
    },
    handleSelect(key) {
      this.activeIndex = key;
      if(key === '3') {
        // this.$router.replace({
        //   path: '/login'
        // })
      } 
    }
  }
}
</script>

<style scoped lang="less">
.homePage {
  height: 100vh;
  .header {
    height: 80px;
    width: 100%;
    z-index: 9;
    position: relative;
    .logo1 {
      width: 100%;
      height: 100%;
    }
    .logo {
      position: absolute;
      left:10%;
			top:20%;
      width: 72px;
      height: 72px;
      z-index: 99;
    }
    .navContent {
      position: absolute;
      right:15%;
			top:10%;
      margin-left: 19%;
      ul {
        display: flex;
        .active {
          color: #fff;
        }
        li {
          margin: 20px 20px;
          height: 16px;
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          color: #999;
          letter-spacing: 0;
          line-height: 16px;
          cursor: pointer;
          .dropdown {
            font-family: PingFangSC-Semibold;
            // background-color: rgba(255, 255, 255, 0.3);
            font-size: 16px;
            color: #999;
            letter-spacing: 0;
            line-height: 16px;

            cursor: pointer;
            .activeP {
              color: #fff;
            }
            p:hover {
              color: #fff;
            }
          }
          .dropdown:hover {
            font-weight: 600;
          }
        }
        li:hover {
          color: #FFFFFF;
          font-weight: 600;
        }
      }
    }
    .login {
      color: #fff;
      font-size: 20px;
      position: fixed;
      right: 50px;
      cursor: pointer;
    }
  }
}
@media (max-width:1450px) { 
  .homePage {
    height: 100vh;
    .header {
      height: 80px;
      width: 100%;
      z-index: 9;
      position: relative;
      .logo1 {
        width: 100%;
        height: 100%;
      }
      .logo {
        position: absolute;
        left:10%;
        top:20%;
        width: 72px;
        height: 72px;
        z-index: 99;
      }
      .navContent {
        position: absolute;
        right:15%;
        top:10%;
        margin-left: 19%;
        ul {
          display: flex;
          .active {
            color: #fff;
          }
          li {
            margin: 20px 20px;
            height: 16px;
            font-family: PingFangSC-Semibold;
            font-size: 14px;
            color: #999;
            letter-spacing: 0;
            line-height: 16px;
            
            cursor: pointer;
            .dropdown {
              font-family: PingFangSC-Semibold;
              font-size: 14px;
              color: #999;
              letter-spacing: 0;
              line-height: 16px;
              
              cursor: pointer;
              .activeP {
                color: #fff;
                font-weight: 600;
              }
              p:hover {
                color: #fff;
                font-weight: 600;
              }
            }
          }
          li:hover {
            color: #FFFFFF;
            font-weight: 600;
          }
        }
      }
      .login {
        color: #fff;
        font-size: 20px;
        position: fixed;
        right: 50px;
        cursor: pointer;
      }
    }
  }
}
</style>
