var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homePage"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"bottomBox"},[_c('div',{staticClass:"boxTop"},[_vm._m(2),_c('div',{staticClass:"leftItem2"},[_vm._m(3),_c('div',{staticClass:"itemBottom"},[_c('div',{staticClass:"itemBottomLeft"},[_c('p',{on:{"click":_vm.getHomePages}},[_vm._v("首页")]),_c('p',{on:{"click":_vm.getAbouts}},[_vm._v("关于我们")]),_c('p',{on:{"click":_vm.getContactUs}},[_vm._v("联系我们")])]),_c('div',{staticClass:"itemBottomRight"},[_c('p',{on:{"click":_vm.getGreenEnergy}},[_vm._v("绿色能源板块")]),_c('p',{on:{"click":_vm.getIoTSectors}},[_vm._v("工业物联网板块")])])])]),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":require("../../assets/images/banner5.png"),"alt":""}}),_c('div',{staticClass:"picturer"},[_c('p',{staticClass:"p1"},[_vm._v("您的需求是我们合作的开始")]),_c('p',{staticClass:"p2"},[_vm._v("我们时刻期待与您的沟通")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic1"},[_c('img',{staticClass:"imgs",attrs:{"src":require("../../assets/images/联系方式背景.png"),"alt":""}}),_c('div',{staticClass:"picBox"},[_c('div',{staticClass:"picBoxTop"},[_c('p',{staticClass:"p1"},[_vm._v("联系方式")]),_c('p',{staticClass:"p2"},[_vm._v("Contact Information")])]),_c('div',{staticClass:"picBoxBottom"},[_c('div',{staticClass:"picTop"},[_c('div',{staticClass:"picTopItem"},[_c('img',{attrs:{"src":require("../../assets/images/电话.png"),"alt":""}}),_c('div',{staticClass:"picTopItemRight"},[_c('p',{staticClass:"p1"},[_vm._v("电话 ")]),_c('p',{staticClass:"p2"},[_vm._v("024-66189888 ")])])]),_c('div',{staticClass:"picTopItem"},[_c('img',{attrs:{"src":require("../../assets/images/邮箱.png"),"alt":""}}),_c('div',{staticClass:"picTopItemRight"},[_c('p',{staticClass:"p1"},[_vm._v("邮箱 ")]),_c('p',{staticClass:"p2"},[_vm._v("LNZX@isoftstone.com ")])])]),_c('div',{staticClass:"picTopItem"},[_c('img',{attrs:{"src":require("../../assets/images/地址.png"),"alt":""}}),_c('div',{staticClass:"picTopItemRight"},[_c('p',{staticClass:"p1"},[_vm._v("地址 ")]),_c('p',{staticClass:"p2"},[_vm._v("辽宁省沈阳市沈河区青年大街268-1号2101")])])])]),_c('img',{attrs:{"src":require("../../assets/images/小地图.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftItem1"},[_c('div',{staticClass:"itemTop"},[_c('span',{staticClass:"span1"},[_vm._v("联系我们")]),_c('span',{staticClass:"span2"},[_vm._v("Contact us")])]),_c('p',{staticClass:"p1",staticStyle:{"margin-top":"40px"}},[_vm._v("客服电话：024-66189888")]),_c('p',{staticClass:"p1"},[_vm._v("公司邮箱：LNZX@isoftstone.com")]),_c('p',{staticClass:"p1",staticStyle:{"height":"20px"}},[_vm._v("公司地址：辽宁省沈阳市沈河区青年")]),_c('p',{staticClass:"p1",staticStyle:{"margin-left":"70px"}},[_vm._v("大街268-1号2101")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itemTop"},[_c('span',{staticClass:"span1"},[_vm._v("快捷导航")]),_c('span',{staticClass:"span2"},[_vm._v("Quick Navigation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftItem3"},[_c('div',{staticClass:"itemTop"},[_c('span',{staticClass:"span1"},[_vm._v("关注我们")]),_c('span',{staticClass:"span2"},[_vm._v("Follow us")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"rightItem",staticStyle:{"margin-right":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/ios下载码.png"),"alt":""}}),_c('p',[_vm._v("扫描下载通通APP（IOS）")])]),_c('div',{staticClass:"rightItem"},[_c('img',{attrs:{"src":require("../../assets/images/android下载码.png"),"alt":""}}),_c('p',[_vm._v("扫描下载通通APP（Android)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxBottom"},[_c('div',{staticClass:"lefts"},[_c('div',{staticClass:"leftC"},[_vm._v("c")]),_c('p',[_vm._v("辽宁软通智新科技有限公司版权所有")])]),_c('a',{staticClass:"filingNumber",staticStyle:{"margin":"0 50px"},attrs:{"target":"_blank","href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v("辽ICP备2023010593号-2")]),_c('div',{staticClass:"lefts"},[_c('img',{attrs:{"src":require("../../assets/images/备案图标.png"),"alt":""}}),_c('p',{staticStyle:{"margin":"3px 0 0 10px"}},[_vm._v("辽公网安备21010302666676号")])])])
}]

export { render, staticRenderFns }