<template>
    <div class="img">
      <img class="imgLogo" src="../assets/通通logo1.png" alt="">
      <div class="navContent">
        <div class="navContentLeft">
          <img class="navImg" src="../assets/账号登录背景小.png" alt="">
        </div>
        <div class="navContentRight" v-if="!switchPages">
          <div class="welcome">
            <p class="wel">欢迎登录</p>
            <p class="tongtong">通通换电后台系统</p>
          </div>
          <div class="tabs">
            <el-tabs v-model="activeName">
              <el-tab-pane label="账号登录" name="first">
                <el-input v-model="account" style="margin: 10px 0;" placeholder="请输入账号"></el-input>
                <el-input v-model="password" type="password" show-password placeholder="请输入密码"></el-input>
              </el-tab-pane>
              <el-tab-pane label="短信登录" name="second">
                <el-input v-model="phone" style="margin: 10px 0;" placeholder="请输入手机号码"></el-input>
                <el-input v-model="number" placeholder="请输入验证码">
                  <el-button style="margin: 5px 5px 0 0; color: #2C57FF;" slot="suffix" type="text">获取验证码</el-button>
                </el-input>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="checkbox">
            <el-checkbox></el-checkbox>
            <p class="read">我已阅读并同意</p>
            <p class="policy">《隐私政策》《服务协议》</p>
          </div>
          <div style="margin-top: 30px;">
            <el-button @click="backHome" type="primary">登录</el-button>
          </div>
          <div class="foot">
            <div class="register">
              <p class="registerPo">还没有账号？</p>
              <!-- <router-view></router-view> -->
              <p @click="registrationPage" class="registerPt">立即注册</p>
            </div>
            <div @click="clickGo" class="pass">
              忘记密码
            </div>
          </div>
        </div>
        <div class="navContentRight" v-else>
          <span class="verify">安全验证</span>
          <p class="texts">您的账号可能存在安全风险，为了确保为您本人操作，请先进行安全验证</p>
          <div>
            <el-input v-model="phone" style="margin: 10px 0;" placeholder="请输入手机号码"></el-input>
            <el-input v-model="number" placeholder="请输入验证码">
              <el-button style="margin: 5px 5px 0 0; color: #2C57FF;" slot="suffix" type="text">获取验证码</el-button>
            </el-input>
          </div>
          <div style="margin-top: 30px;">
            <el-button type="primary">确定</el-button>
          </div>
          <div class="login">
            <div></div>
            <div class="loginR">
              <span class="yiyou">已有账号？</span>
              <span @click="clickGoHome" class="loginGo">马上登录</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footButtom">
        <a class="filingNumber"  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2023010593号-2</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'loginPage',
    props: {
      
    },
    data() {
      return {
        activeName: 'first',
        account: '',
        password: '',
        phone: '',
        number: '',
        switchPages: false
      }
    },
    methods: {
      clickGo() {
        this.switchPages = true
      },
      clickGoHome() {
        this.switchPages = false
      },
      registrationPage() {
        this.$router.replace({
          path: '/register'
        })
      },
      backHome() {
        // this.$router.replace({
        //   path: '/home'
        // })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  /deep/.el-tabs__item {
    // width: 110px;
    height: 35px;
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #999;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    font-weight: 500;
  }
  /deep/.el-tabs__nav-wrap::after {
    background: rgba(255,255,255,0.40);
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #2C57FF;
      border-color: #2C57FF;
  }
  /deep/.el-tabs__item.is-active {
    color: #2C57FF;
  }
  /deep/.el-tabs__active-bar {
    background-color: #2C57FF;
  }
  /deep/.el-tabs__item:hover {
      color: #2C57FF;
      cursor: pointer;
  }
  /deep/.el-input__inner {
    height: 50px;
  }
  /deep/.el-button--primary {
    width: 100%;
    height: 50px;
    background-color: #2C57FF;
    border-color: #2C57FF;
    font-size: 20px;
  }
  .img {
    width: 100%;
    height: 100vh; 
    background-image: url(../assets/账号登录背景大.png);
    background-size: cover;
    background-repeat: no-repeat;
    // background: red;
    .imgLogo {
      width:162px;
      height: 52px;
      margin: 57px 0 100px 80px;
      box-sizing: border-box;
    }
    .navContent {
      box-sizing: border-box;
      // margin: 100px 0 0 80px;
      display: flex;
      justify-content: center;
      .navContentLeft {
        width: 800px;
        height: 480px;
        .navImg {
          width: 100%;
          height: 100%;
        }
      }
      .navContentRight {
        align-self: center;
        opacity: 1;
        width: 470px;
        height: 480px;
        background: rgba(255,255,255,0.40);
        border-radius: 0 20px 20px 0px;
        padding: 50px;
        box-sizing: border-box;
        .welcome {
          display: flex;
          align-items: end;
          .wel {
            width: 180px;
            display: flex;
            align-items: start;
            height: 36px;
            font-family: PingFangSC-Semibold;
            font-size: 36px;
            color: #333333;
            letter-spacing: 0;
            text-align: center;
            line-height: 36px;
            font-weight: 600;
            margin-right: 10px;
          }
          .tongtong {
            width: 200px;
            height: 24px;
            font-family: PingFangSC-Regular;
            font-size: 22px;
            color: #999999;
            letter-spacing: 0;
            text-align: center;
            line-height: 22px;
            font-weight: 400;
          }
        }
        .tabs {
          margin-top: 30px;
        }
        .checkbox {
          display: flex;
          margin-top: 10px;
          .read {
            margin: 2px 0 0 7px;
            width: 112px;
            height: 16px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
          }
          .policy {
            cursor: pointer; 
            margin-top: 2px;
            width: 192px;
            height: 16px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #2C57FF;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
          }
        }
        .foot {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .register {
            display: flex;
            .registerPo {
              width: 96px;
              height: 16px;
              font-family: PingFangSC-Regular;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              line-height: 16px;
              font-weight: 400;
            }
            .registerPt {
              cursor: pointer; 
              width: 64px;
              height: 16px;
              font-family: PingFangSC-Regular;
              font-size: 16px;
              color: #2C57FF;
              letter-spacing: 0;
              line-height: 16px;
              font-weight: 400;
            }
          }
          .pass {
            cursor: pointer; 
            width: 64px;
            height: 16px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #2C57FF;
            letter-spacing: 0;
            text-align: right;
            line-height: 16px;
            font-weight: 400;
          }
        }
        .verify {
          width: 144px;
          height: 36px;
          font-family: PingFangSC-Semibold;
          font-size: 36px;
          color: #333333;
          letter-spacing: 0;
          line-height: 36px;
          font-weight: 600
        }
        .texts {
          width: 399px;
          height: 48px;
          font-family: PingFangSC-Regular;
          font-size: 18px;
          color: #333333;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
          margin: 50px 0 20px 0;
        }
        .login {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          .loginR {
            .yiyou {
              width: 80px;
              height: 16px;
              font-family: PingFangSC-Regular;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              text-align: right;
              line-height: 16px;
              font-weight: 400;
            }
            .loginGo {
              width: 64px;
              height: 16px;
              font-family: PingFangSC-Regular;
              font-size: 16px;
              color: #2C57FF;
              letter-spacing: 0;
              text-align: right;
              line-height: 16px;
              font-weight: 400;
              cursor: pointer; 
            }
          }
        }
      }
      
    }
    .footButtom {
      width: 100%;
      display: flex;
      justify-content: center;
      position: fixed;
      bottom: 20px;
      .filingNumber {
        text-decoration: none;
        color: #999;
        font-family: PingFangSC-Regular;
        margin-top: 50px;
        font-size: 14px;
      }
    }
  }
  </style>
  