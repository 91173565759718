<template>
	<div class="homePage">
		<div class="picture">
			<img src="../../assets/images/banner3.png" alt="">
			<div class="picturer">
				<p class="p1">顺应产业变革</p>
				<p class="p2">聚焦企业数字化转型</p>
			</div>
		</div>
		<div class="pic">
			<img class="imgs" src="../../assets/images/方案概述背景.png" alt="">
			<div class="picBox">
				<div class="picBoxTop">
					<p class="p1" >方案概述</p>
					<p class="p2" style="margin-left: -8px;">Plan Overview</p>
				</div>
				<div class="picBoxBottom">
					<!-- <img src="../../assets/images/文字4.png" alt=""> -->
					<div class="left">
						<p style="text-indent:2em"> 辽宁软通智新紧跟国家工业互联网发展政策，面向产业与企业数字化转型目标，以数字工厂为引领，提供数字化工厂5大核心能力与综合服务，采用整体解决方案+产品+服务模式，确保数字化工厂的顺利落地。</p>
						<p style="margin: 10px 0;">1.结合数字工厂经验，全面覆盖中国数字化工厂主要建设内容</p>
						<p style="margin: 10px 0;">2.辽宁软通智新工业互联网数字化工厂5大核心能力支持工业企业全价值链数字化智能化</p>
						<p>3.全面的综合服务与生态整合能力，端到端一站式服务企业数字化</p>
					</div>
					<img src="../../assets/images/方案概述图.png" alt="">
				</div>
			</div>
		</div>
		<div class="pic1" style="background: #f7f7f7;padding-top: 50px;">
			<div class="picBox">
				<div class="picBoxTop">
					<p class="p1">目标客户</p>
					<p class="p2">Target Customers</p>
				</div>
				<div class="picBoxBottom">
					<img src="../../assets/images/目标客户图.png" alt="">
					<div class="characters">
						<div class="item" style="margin: 50px 0 0 0;">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>行业</p>
							</div>
							<p class="p2">智能制造企业、电气企业、供应链企业、家电企业、铝业企业、能源企业、双碳、石化企业、水泥企业等</p>
						</div>
						<div class="item">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>目标客群及决策人</p>
							</div>
							<p class="p2">1.主要客户：有数字化转型需求的公司</p>
							<p class="p2" style="margin: 5px 0 5px 23px;">2.拓展客户：流程制造及离散制造企业</p>
							<p class="p2">3.客户关键决策人物：信息技术/业务管理部门负责人</p>
						</div>
						<div class="item">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>外包业务服务领域市场诉求</p>
							</div>
							<p class="p2">1.大多数企业对于信息技术的应用认知还停留在部署IT系统的阶段，并且经过多年传统信息技术的应用，数据孤岛、基础数据不准等问题始终困扰着企业</p>
							<p class="p2" style="margin: 5px 0 5px 23px;">2.云、大数据、AI、IoT等新兴技术快速发展，新旧问题叠加让传统制造企业理解、应用、掌握这些技术变得更困难</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pic2" style="padding-top: 50px;">
			<img class="imgs" src="../../assets/images/方案概述背景.png" alt="">
			<div class="picBox">
				<div class="picBoxTop">
					<p class="p1">方案简介</p>
					<p class="p2">Plan Introduction</p>
				</div>
				<div class="picBoxBottom">
					<img style="margin-right: 30px;" src="../../assets/images/方案介绍图.png" alt="">
					<div class="characters">
						<div class="item" style="margin: 50px 0 0 0;">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>聚焦数字化工厂建设三大核心动能</p>
							</div>
							<p class="p2">1.物理工厂：以实体工厂结构、流程和系统支持生产制造的运行，保证资源管理的有效性和敏捷性</p>
							<p class="p2" style="margin: 5px 0 5px 23px;">2.孪生工厂：利用新一代信息技术支撑实体工厂智能化升级，实现全面的降本、增效、提质、减存</p>
							<p class="p2">3.服务驱动：以人为本，为客户提供端到端的综合型服务，保证数字技术和业务的高效融合应用</p>
						</div>
						<div class="item">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>数字化工厂方案支撑工厂实现“五化”</p>
							</div>
							<p class="p2">1.低碳化-绿色化生产</p>
							<p class="p2" style="margin: 5px 0 5px 23px;">2.模型化-生产设备及过程建模</p>
							<p class="p2">3.智能化-数据驱动生产</p>
							<p class="p2" style="margin: 5px 0 5px 23px;">4.自动化-全价值链联动</p>
							<p class="p2">5.协同化-协同设计研发</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pic1">
			<div class="picBox">
				<div class="picBoxTop">
					<p class="p1">客户案例</p>
					<p class="p2" style="margin-left: -20px;">Customer Cases</p>
				</div>
				<div class="picBoxBottom">
					<div class="characters">
						<p class="pTitle">某XXX石油公司数字化转型</p>
						<div class="item" style="margin: 20px 0 0 0;">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>背景</p>
							</div>
							<p class="p2">1.客户是某XXX石油公司设立的独资企业，建立一个总投资人民币370亿元的新石化材料基地，下属多个生产工序分场，拥有国际领先技术和完整的生产线</p>
							<p class="p2" style="margin: 5px 0 5px 23px;">2.企业生产过程数据不透明，调度不统一，设备台账无标准，生命周期无记录，报警监控滞后，消息传递不及时 </p>
						</div>
						<div class="item">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>提供的方案和服务</p>
							</div>
							<p class="p2">通过项目的集成平台的建设整合公司所有信息系统的接入和展现功能，实现统一内容管理、统一协同工作、统一展现界面、统一身份管理、统一权限管理、统一访问入口</p>
						</div>
						<div class="item">
							<div class="title">
								<img src="../../assets/images/箭头.png" alt="">
								<p>亮点和价值</p>
							</div>
							<p class="p2">业务数据完整性上升94%</p>
							<p class="p2" style="margin: 5px 0 5px 23px;">生产统计效率上升84%</p>
							<p class="p2">监控过失率下降46%</p>
						</div>
					</div>
					<img style="margin-left: 10px;" src="../../assets/images/客户案例图.png" alt="">
				</div>
			</div>
		</div>
		<div class="bottomBox">
			<div class="boxTop">
				<div class="leftItem1">
					<div class="itemTop">
						<span class="span1">联系我们</span><span class="span2">Contact us</span>
					</div>
					<p class="p1" style="margin-top: 40px;">客服电话：024-66189888</p>
					<p class="p1">公司邮箱：LNZX@isoftstone.com</p>
					<p class="p1" style="height: 20px;">公司地址：辽宁省沈阳市沈河区青年</p>
					<p class="p1" style="margin-left: 70px;">大街268-1号2101</p>
				</div>
				<div class="leftItem2">
					<div class="itemTop">
						<span class="span1">快捷导航</span><span class="span2">Quick Navigation</span>
					</div>
					<div class="itemBottom">
						<div class="itemBottomLeft">
							<p @click="getHomePages">首页</p>
							<p @click="getAbouts">关于我们</p>
							<p @click="getContactUs">联系我们</p>
						</div>
						<div class="itemBottomRight">
							<p @click="getGreenEnergy">绿色能源板块</p>
							<p @click="getIoTSectors">工业物联网板块</p>
						</div>
					</div>
				</div>
				<div class="leftItem3">
					<div class="itemTop">
						<span class="span1">关注我们</span><span class="span2">Follow us</span>
					</div>
					<div class="right">
						<div class="rightItem" style="margin-right: 20px;">
							<img src="../../assets/images/ios下载码.png" alt="">
							<p>扫描下载通通APP（IOS）</p>
						</div>
						<div class="rightItem">
							<img src="../../assets/images/android下载码.png" alt="">
							<p>扫描下载通通APP（Android)</p>
						</div>
					</div>
				</div>
			</div>
			<div class="boxBottom">
				<div class="lefts">
					<div class="leftC">c</div>
					<p>辽宁软通智新科技有限公司版权所有</p>
				</div>
        <a class="filingNumber" style="margin: 0 50px;"  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2023010593号-2</a>
        <div class="lefts">
					<img src="../../assets/images/备案图标.png" alt="">
					<p style="margin: 3px 0 0 10px;">辽公网安备21010302666676号</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	mounted() {
		this.scrollToTop();
	},
	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		getHomePages() {
			this.$emit('jumpToPage', '1' )
		},
		getAbouts() {
			this.$emit('jumpToPage', '3' )
		},
		getContactUs() {
			this.$emit('jumpToPage', '4' )
		},
		getGreenEnergy() {
			this.$emit('jumpToPage', '2-1' )
		},	
		getIoTSectors() {
			this.$emit('jumpToPage', '2-2' )
		}
	}
}
</script>

<style lang="less" scoped>
.homePage {
	height: 100%;
	.picture {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
		}
		.picturer {
			position: absolute;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%);
			.p1 {
				// width: 396px;
				height: 66px;
				font-family: SourceHanSansCN-Bold;
				font-size: 66px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				font-weight: 700;
				margin-bottom: 30px;
			}
			.p2 {
				// width: 440px;
				height: 40px;
				font-family: SourceHanSansCN-Normal;
				font-size: 40px;
				color: #FFFFFF;
				letter-spacing: 0;
				text-align: center;
				line-height: 40px;
				font-weight: 400;
				margin-left: -10px;
			}
		}
	}
		
	.newsBottom {
		width: 100%;
		height: 900px;
		display: flex;
		align-items: center;
		flex-direction: column;
		background: #fafafa;
		.title {
			margin: 55px 0;
			.news {
				width: 144px;
				height: 50px;
				font-family: PingFangSC-Semibold;
				font-size: 36px;
				color: #222222;
				letter-spacing: 0;
				// text-align: center;
				font-weight: 600;
			}
			.new {
				// width: 200px;
				height: 24px;
				font-family: ArialMT;
				font-size: 24px;
				color: #666666;
				margin-left: -40px;
				letter-spacing: 0;
				// text-align: center;
				line-height: 24px;
				font-weight: 400;
			}
		}
		.images {
			display: flex;
			.characters {
				width: 500px;
				height: 320px;
				font-family: PingFangSC-Regular;
				font-size: 18px;
				color: #222222;
				letter-spacing: 0;
				line-height: 32px;
				font-weight: 400;
				margin: 50px 50px 0 0;
			}
		}
		
	}
	.pic {
		width: 100%;
		// height: 850px;
		position: relative;
		background: #fff;
		font-size: 0px;
		.imgs {
			width: 100%;
			height: 100%;
			// margin-top: 3px;
		}
		.picBox {
			width: 66.6%;
			// height: 720px;
			padding-left: 20px;
			position: absolute;
			background: #fff;
			top: 0;
			left: 16.6%;
			display: flex;
			align-items: center;
			flex-direction: column;
			.picBoxTop {
				.p1 {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					text-align: center;
					font-weight: 600;
					margin: 50px 0 5px 0;
				}
				.p2 {
					// width: 193px;
					margin: 0 0 60px -30px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					letter-spacing: 0;
					text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.picBoxBottom {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.left {
					width: 45%;
					margin-top: 50px;
					p {
						font-family: PingFangSC-Regular;
						font-size: 20px;
						color: #222222;
						letter-spacing: 0;
						line-height: 28px;
						font-weight: 400;
					}
				}
				img{
					width: 47%;
				}
			}
		}
	}
	.pic1 {
		width: 100%;
		// height: 900px;
		display: flex;
		justify-content: center;
		.picBox {
			width: 66.6%;
			// height: 900px;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 30px 0;
			.picBoxTop {
				.p1 {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					text-align: center;
					font-weight: 600;
					margin: 66px 0 5px 0;
				}
				.p2 {
					// width: 193px;
					margin: 0 0 60px -30px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					letter-spacing: 0;
					text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.picBoxBottom {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.characters {
					width: 50%;
					.pTitle {
						height: 24px;
						font-family: PingFangSC-Semibold;
						font-size: 24px;
						color: #333333;
						letter-spacing: 0;
						line-height: 24px;
						font-weight: 600;
						margin-top: 40px;
					}
					.item {
						margin-top: 20px;
						.title {
							display: flex;
							margin: 0 0 10px 0;
							img {
								width: 12px;
								height: 12px;
								margin: 5px 10px 0 0;
							}
							p {
								height: 20px;
								font-family: PingFangSC-Semibold;
								font-size: 20px;
								color: #333333;
								letter-spacing: 0;
								line-height: 20px;
								font-weight: 600;
							}
						}
						.p2 {
							font-family: PingFangSC-Regular;
							font-size: 20px;
							color: #222222;
							letter-spacing: 0;
							line-height: 28px;
							font-weight: 400;
							margin-left: 23px;
						}
					}
				}
				img{
					width: 47%;
				}
			}
		}
	}
	.pic2 {
		width: 100%;
		// height: 850px;
		position: relative;
		background: #fff;
		.imgs {
			width: 100%;
			height: 100%;
		}
		.picBox {
			width: 66.6%;
			// height: 800px;
			position: absolute;
			background: #fff;
			left: 16.6%;
			top: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			.picBoxTop {
				.p1 {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					text-align: center;
					font-weight: 600;
					margin: 66px 0 5px 0;
				}
				.p2 {
					// width: 193px;
					margin: 0 0 60px -30px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					letter-spacing: 0;
					text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.picBoxBottom {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.characters {
					width: 50%;
					.pTitle {
						height: 24px;
						font-family: PingFangSC-Semibold;
						font-size: 24px;
						color: #333333;
						letter-spacing: 0;
						line-height: 24px;
						font-weight: 600;
						margin-top: 40px;
					}
					.item {
						margin-top: 30px;
						.title {
							display: flex;
							margin: 0 0 10px 0;
							img {
								width: 12px;
								height: 12px;
								margin: 5px 10px 0 0;
							}
							p {
								height: 20px;
								font-family: PingFangSC-Semibold;
								font-size: 20px;
								color: #333333;
								letter-spacing: 0;
								line-height: 20px;
								font-weight: 600;
							}
						}
						.p2 {
							font-family: PingFangSC-Regular;
							font-size: 20px;
							color: #222222;
							letter-spacing: 0;
							line-height: 28px;
							font-weight: 400;
							margin-left: 23px;
						}
					}
				}
				img{
					width: 47%;
				}
			}
		}
	}
	.bottomBox {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #f7f7f7;
		.boxTop {
			width: 66.6%;
			height: 440px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #EEE;
			.leftItem1 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.p1 {
					width: 234px;
					height: 40px;
					font-family: PingFangSC-Semibold;
					font-size: 14px;
					color: #999999;
					letter-spacing: 0;
					line-height: 20px;
					font-weight: 600;
				}
			}
			.leftItem2 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.itemBottom {
					width: 230px;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					p {
						cursor: pointer;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 14px;
						font-weight: 600;
					}
				}
			}
			.leftItem3 {
				width: 33.4%;
				padding-top: 100px;
				.itemTop {
					border-bottom: 1px solid #DFDFDF;
					width: 230px;
					padding-bottom: 10px;
					.span1 {
						width: 64px;
						height: 16px;
						font-family: PingFangSC-Semibold;
						font-size: 16px;
						color: #222222;
						letter-spacing: 0;
						line-height: 16px;
						font-weight: 600;
						margin-right: 20px;
					}
					.span2 {
						width: 62px;
						height: 12px;
						font-family: PingFangSC-Regular;
						font-size: 12px;
						color: #999999;
						letter-spacing: 0;
						line-height: 12px;
						font-weight: 400;
					}
				}
				.right {
					display: flex;
					margin-top: 50px;
					.rightItem {
						width: 200px;
						height: 240px;
						background: #fff;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-radius: 10px;
						p {
							width: 200px;
							height: 14px;
							font-family: PingFangSC-Regular;
							font-size: 14px;
							color: #222222;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 400;
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					}
				}
			}
			
		}
		.boxBottom {
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			.lefts {
				display: flex;
				.leftC {
					width: 11px;
					height: 11px;
					border-radius: 50%;
					border: 1px solid #333;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					color: #333333;
					letter-spacing: 0;
				}
			}
			p { 
				height: 14px;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
			.filingNumber {
				text-decoration: none;
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #333333;
				letter-spacing: 0;
				line-height: 14px;
				font-weight: 400;
			}
		}
	}
}
@media (max-width:1500px) { 
	.homePage {
		height: 100%;
		.picture {
			width: 100%;
			position: relative;
			img {
				width: 100%;
				height: 100%;
			}
			.picturer {
				position: absolute;
				left:50%;
				top:50%;
				transform: translate(-50%, -50%);
				.p1 {
					// width: 396px;
					height: 66px;
					font-family: SourceHanSansCN-Bold;
					font-size: 60px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					font-weight: 700;
					margin-bottom: 30px;
				}
				.p2 {
					// width: 440px;
					height: 40px;
					font-family: SourceHanSansCN-Normal;
					font-size: 35px;
					color: #FFFFFF;
					letter-spacing: 0;
					text-align: center;
					line-height: 40px;
					font-weight: 400;
					margin-left: -5px;
				}
			}
		}
			
		.newsBottom {
			width: 100%;
			height: 900px;
			display: flex;
			align-items: center;
			flex-direction: column;
			background: #fafafa;
			.title {
				margin: 55px 0;
				.news {
					width: 144px;
					height: 50px;
					font-family: PingFangSC-Semibold;
					font-size: 36px;
					color: #222222;
					letter-spacing: 0;
					// text-align: center;
					font-weight: 600;
				}
				.new {
					// width: 200px;
					height: 24px;
					font-family: ArialMT;
					font-size: 24px;
					color: #666666;
					margin-left: -40px;
					letter-spacing: 0;
					// text-align: center;
					line-height: 24px;
					font-weight: 400;
				}
			}
			.images {
				display: flex;
				.characters {
					width: 500px;
					height: 320px;
					font-family: PingFangSC-Regular;
					font-size: 18px;
					color: #222222;
					letter-spacing: 0;
					line-height: 32px;
					font-weight: 400;
					margin: 50px 50px 0 0;
				}
			}
			
		}
		.pic {
			width: 100%;
			height: 750px;
			position: relative;
			background: #fff;
			.imgs {
				width: 100%;
				height: 100%;
			}
			.picBox {
				width: 76.6%;
				// height: 620px;
				padding-left: 20px;
				position: absolute;
				background: #fff;
				top: 2%;
				left: 11.6%;
				display: flex;
				align-items: center;
				flex-direction: column;
				.picBoxTop {
					.p1 {
						width: 144px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						text-align: center;
						font-weight: 600;
						margin: 50px 0 5px 0;
					}
					.p2 {
						// width: 193px;
						margin: 0 0 60px -30px;
						height: 24px;
						font-family: ArialMT;
						font-size: 24px;
						color: #666666;
						letter-spacing: 0;
						text-align: center;
						line-height: 24px;
						font-weight: 400;
					}
				}
				.picBoxBottom {
					width: 100%;
					display: flex;
					justify-content: space-between;
					background: #fff;
					.left {
						width: 45%;
						margin-top: 50px;
						p {
							// width: 500px;
							// height: 392px;
							font-family: PingFangSC-Regular;
							font-size: 18px;
							color: #222222;
							letter-spacing: 0;
							line-height: 28px;
							font-weight: 400;
						}
					}
					img{
						width: 45%;
					}
				}
			}
		}
		.pic1 {
			width: 100%;
			height: 800px;
			display: flex;
			justify-content: center;
			.picBox {
				width: 76.6%;
				height: 800px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 30px;
				.picBoxTop {
					.p1 {
						width: 144px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						text-align: center;
						font-weight: 600;
						margin: 20px 0 5px 0;
					}
					.p2 {
						// width: 193px;
						margin: 0 0 60px -30px;
						height: 24px;
						font-family: ArialMT;
						font-size: 24px;
						color: #666666;
						letter-spacing: 0;
						text-align: center;
						line-height: 24px;
						font-weight: 400;
					}
				}
				.picBoxBottom {
					width: 100%;
					display: flex;
					justify-content: space-between;
					.characters {
						width: 50%;
						.pTitle {
							height: 24px;
							font-family: PingFangSC-Semibold;
							font-size: 22px;
							color: #333333;
							letter-spacing: 0;
							line-height: 22px;
							font-weight: 600;
							margin-top: 5px;
						}
						.item {
							margin-top: 10px;
							.title {
								display: flex;
								margin: 0 0 10px 0;
								img {
									width: 12px;
									height: 12px;
									margin: 5px 10px 0 0;
								}
								p {
									height: 20px;
									font-family: PingFangSC-Semibold;
									font-size: 18px;
									color: #333333;
									letter-spacing: 0;
									line-height: 18px;
									font-weight: 600;
								}
							}
							.p2 {
								font-family: PingFangSC-Regular;
								font-size: 18px;
								color: #222222;
								letter-spacing: 0;
								line-height: 25px;
								font-weight: 400;
								margin-left: 23px;
							}
						}
					}
					img{
						width: 47%;
					}
				}
			}
		}
		.pic2 {
			width: 100%;
			height: 750px;
			position: relative;
			background: #fff;
			.imgs {
				width: 100%;
				height: 100%;
			}
			.picBox {
				width: 76.6%;
				// height: 700px;
				position: absolute;
				background: #fff;
				left: 11.6%;
				top: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				.picBoxTop {
					.p1 {
						width: 144px;
						height: 50px;
						font-family: PingFangSC-Semibold;
						font-size: 36px;
						color: #222222;
						letter-spacing: 0;
						text-align: center;
						font-weight: 600;
						margin: 66px 0 5px 0;
					}
					.p2 {
						// width: 193px;
						margin: 0 0 60px -30px;
						height: 24px;
						font-family: ArialMT;
						font-size: 24px;
						color: #666666;
						letter-spacing: 0;
						text-align: center;
						line-height: 24px;
						font-weight: 400;
					}
				}
				.picBoxBottom {
					width: 100%;
					display: flex;
					justify-content: space-between;
					.characters {
						width: 50%;
						.pTitle {
							height: 24px;
							font-family: PingFangSC-Semibold;
							font-size: 24px;
							color: #333333;
							letter-spacing: 0;
							line-height: 24px;
							font-weight: 600;
							margin-top: 40px;
						}
						.item {
							margin-top: 30px;
							.title {
								display: flex;
								margin: 0 0 10px 0;
								img {
									width: 12px;
									height: 12px;
									margin: 5px 10px 0 0;
								}
								p {
									height: 20px;
									font-family: PingFangSC-Semibold;
									font-size: 20px;
									color: #333333;
									letter-spacing: 0;
									line-height: 20px;
									font-weight: 600;
								}
							}
							.p2 {
								font-family: PingFangSC-Regular;
								font-size: 20px;
								color: #222222;
								letter-spacing: 0;
								line-height: 28px;
								font-weight: 400;
								margin-left: 23px;
							}
						}
					}
					img{
						width: 47%;
					}
				}
			}
		}
		.bottomBox {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #f7f7f7;
			.boxTop {
				width: 76.6%;
				height: 440px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #EEE;
				.leftItem1 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.p1 {
						width: 234px;
						height: 40px;
						font-family: PingFangSC-Semibold;
						font-size: 14px;
						color: #999999;
						letter-spacing: 0;
						line-height: 20px;
						font-weight: 600;
					}
				}
				.leftItem2 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.itemBottom {
						width: 230px;
						margin-top: 40px;
						display: flex;
						justify-content: space-between;
						p {
							cursor: pointer;
							height: 40px;
							font-family: PingFangSC-Semibold;
							font-size: 14px;
							color: #999999;
							letter-spacing: 0;
							line-height: 14px;
							font-weight: 600;
						}
					}
				}
				.leftItem3 {
					width: 33.4%;
					padding-top: 100px;
					.itemTop {
						border-bottom: 1px solid #DFDFDF;
						width: 230px;
						padding-bottom: 10px;
						.span1 {
							width: 64px;
							height: 16px;
							font-family: PingFangSC-Semibold;
							font-size: 16px;
							color: #222222;
							letter-spacing: 0;
							line-height: 16px;
							font-weight: 600;
							margin-right: 20px;
						}
						.span2 {
							width: 62px;
							height: 12px;
							font-family: PingFangSC-Regular;
							font-size: 12px;
							color: #999999;
							letter-spacing: 0;
							line-height: 12px;
							font-weight: 400;
						}
					}
					.right {
						display: flex;
						margin-top: 50px;
						.rightItem {
							width: 200px;
							height: 240px;
							background: #fff;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							border-radius: 10px;
							p {
								width: 200px;
								height: 14px;
								font-family: PingFangSC-Regular;
								font-size: 14px;
								color: #222222;
								letter-spacing: 0;
								line-height: 14px;
								font-weight: 400;
								display: flex;
								flex-direction: column;
								align-items: center;
							}
						}
					}
				}
				
			}
			.boxBottom {
				width: 100%;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				.lefts {
					display: flex;
					.leftC {
						width: 11px;
						height: 11px;
						border-radius: 50%;
						border: 1px solid #333;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #333333;
						letter-spacing: 0;
					}
				}
				p { 
					height: 14px;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
				.filingNumber {
					text-decoration: none;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					letter-spacing: 0;
					line-height: 14px;
					font-weight: 400;
				}
			}
		}
	}
}
</style>